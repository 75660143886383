import { Button, Form, Row, Col, InputGroup } from "react-bootstrap";

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import { useRegisterAssociateMutation } from "../slices/associatesSlice";
import { getCurrentDate } from "../utils/getCurrentDate";
import FieldWarning from "../components/FieldWarning";
import { useUpdateMyGymMutation } from "../slices/gymSlice";

const CreatePackageScreen = ({ existingPackages }) => {
  const { userInfo } = useSelector((state) => state.auth);

  const [packageName, setPackageName] = useState();
  const [originalPackageMonths, setOriginalPackageMonths] = useState();
  const [packageMonths, setPackageMonths] = useState();
  const [packageAmount, setPackageAmount] = useState();
  const [offerAmount, setOfferAmount] = useState();
  const [packageExtraDays, setPackageExtraDays] = useState(0);

  const [packageNamePresent, setPackageNamePresent] = useState();
  const [monthsPresent, setMonthsPresent] = useState();
  const [extraDaysPresent, setExtraDaysPresent] = useState();

  const [updatePackage, { isLoading }] = useUpdateMyGymMutation();

  // console.log("existingPackages");
  // console.log(existingPackages);

  useEffect(() => {
    if (packageName?.length > 0 && packageName?.length < 999) {
      setPackageNamePresent(false);
    }

    if (extraDaysPresent > 0 && extraDaysPresent < 32) {
      extraDaysPresent(false);
    }
  }, [packageName, extraDaysPresent]);

  const verifyInputFeilds = async () => {
    let fieldValidationSuccess = true;

    if (packageName?.length < 1 || packageName?.length > 999) {
      setPackageNamePresent(false);
    }

    if (extraDaysPresent < 0 || extraDaysPresent > 31) {
      extraDaysPresent(false);
    }

    if (!fieldValidationSuccess)
      toast.error(
        "Some of the fields have invalid inputs. Please provide valid details"
      );
    return fieldValidationSuccess;
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (await verifyInputFeilds()) {
      const newPckg = {
        price: Number(packageAmount),
        offerPrice: Number(offerAmount),
        originalMonths: Number(originalPackageMonths),
        months: Number(packageMonths),
        extraDays: Number(packageExtraDays),
      };
      // console.log("newPackage");
      // console.log(newPckg);
      // console.log({ ...existingPackages, newPckg });
      try {
        const resPackage = await updatePackage({
          payload: {
            customPackage: {
              ...existingPackages,
              [packageName]: newPckg,
            },
          },
          token: userInfo.token,
        }).unwrap();
        toast.success("Package is added successfully ");
        setPackageName("");
        setPackageExtraDays(0);
        setPackageAmount("");
        setPackageMonths("");
        setOriginalPackageMonths("");
        setOfferAmount("");

        // navigate("/");
      } catch (e) {
        toast.error(e?.data?.error || e.data);
      }
    }
  };

  return (
    <>
      {userInfo.gymId.subscriptionType !== "Premium" ? (
        <>
          <h6>Create Package</h6>
          <Form onSubmit={submitHandler}>
            <Row>
              <Col md={10}>
                <Form.Group as={Row} className="my-2" controlId="firstName">
                  <Form.Label column="true" sm="3">
                    Package Name <label style={{ color: "red" }}>*</label> :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      type="text"
                      value={packageName}
                      onChange={(e) => setPackageName(e.target.value)}
                      placeholder="enter package Name"
                    ></Form.Control>{" "}
                    {packageNamePresent ? (
                      <FieldWarning text="package name should have minimum 1 char and max 100 char" />
                    ) : (
                      ""
                    )}
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="my-2" controlId="firstName">
                  <Form.Label column="true" sm="3">
                    Original Price <label style={{ color: "red" }}>*</label> :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      type="text"
                      value={packageAmount}
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                        setPackageAmount(value);
                      }}
                      placeholder="enter amount"
                    ></Form.Control>{" "}
                    {monthsPresent ? (
                      <FieldWarning text="Package amount should be between Rs 1 and Rs 99999 " />
                    ) : (
                      ""
                    )}
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="my-2" controlId="firstName">
                  <Form.Label column="true" sm="3">
                    Offer Price <label style={{ color: "red" }}>*</label> :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      type="text"
                      value={offerAmount}
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                        setOfferAmount(value);
                      }}
                      placeholder="enter amount"
                    ></Form.Control>{" "}
                    {monthsPresent ? (
                      <FieldWarning text="Package amount should be between Rs 1 and Rs 99999 " />
                    ) : (
                      ""
                    )}
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="my-2" controlId="firstName">
                  <Form.Label column="true" sm="3">
                    Original Months <label style={{ color: "red" }}>*</label> :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      type="text"
                      value={originalPackageMonths}
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                        setOriginalPackageMonths(value);
                      }}
                      placeholder="enter months"
                    ></Form.Control>{" "}
                    {monthsPresent ? (
                      <FieldWarning text="Package months should be between 1 and 60 " />
                    ) : (
                      ""
                    )}
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="my-2" controlId="firstName">
                  <Form.Label column="true" sm="3">
                    Offer Months <label style={{ color: "red" }}>*</label> :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      type="text"
                      value={packageMonths}
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                        setPackageMonths(value);
                      }}
                      placeholder="enter months"
                    ></Form.Control>{" "}
                    {monthsPresent ? (
                      <FieldWarning text="Package months should be between 1 and 60 " />
                    ) : (
                      ""
                    )}
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="my-2" controlId="contactNumber">
                  <Form.Label column="true" sm="3">
                    Extra Days <label style={{ color: "red" }}>*</label> :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      type="text"
                      pattern="[0-9]*"
                      value={packageExtraDays}
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                        setPackageExtraDays(value);
                      }}
                      placeholder="enter extra days"
                    ></Form.Control>
                    {extraDaysPresent ? (
                      <FieldWarning text="Extra days should not be greater than 31" />
                    ) : (
                      ""
                    )}{" "}
                    {/* <Button style={{ margin: "5px" }} type="Submit">
                      Submit
                    </Button> */}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row
              style={{
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                className={`${userInfo.themeColor}ThemeHeaderTop`}
                type="submit"
                disabled={isLoading}
                variant="primary"
                style={{ maxWidth: "10rem" }}
              >
                Submit
              </Button>
            </Row>
          </Form>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default CreatePackageScreen;
