import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Table } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import * as XLSX from "xlsx";
import { useNavigate, useParams } from "react-router-dom";
import Message from "../components/Message";
import { getCurrentDate } from "../utils/getCurrentDate";

import { setCredentials } from "../slices/authSlice";
import VerticallyCenteredModal from "../components/SubmitModal";
import {
  useGetMyWhatsAppAdsQuery,
  useGetWsMessageQuery,
  useSendWsMessageMutation,
} from "../slices/wsMessageSlice";

const WsMessageAdsListScreen = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const [startDate, setStartDate] = useState(getCurrentDate());

  const [expandedMessageId, setExpandedMessageId] = useState(null);

  const toggleMessage = (id) => {
    setExpandedMessageId(expandedMessageId === id ? null : id);
  };

  const [endDate, setEndDate] = useState(getCurrentDate());

  const [getApiQuery, setGetApiQuery] = useState(
    `gymId=${userInfo.gymId._id}&createdAt[gte]=${startDate}T00:00:00.125Z&createdAt[lte]=${endDate}T23:59:59.125Z&sort=-createdAt`
  );

  const {
    data: wsMessages,
    isLoading,
    error,
    refetch,
  } = useGetMyWhatsAppAdsQuery({
    query: getApiQuery,
    token: userInfo ? userInfo.token : "Bearer a",
  });

  const [messagesToList, setMessagesToList] = useState(
    isLoading ? [] : wsMessages ? wsMessages : []
  );

  const [todaysMessages, setTodaysMessages] = useState(
    isLoading ? [] : wsMessages ? wsMessages.length : 0
  );

  const dispatch = useDispatch();

  const handleGetUsers = async () => {
    if (startDate > getCurrentDate())
      toast.error("Start date can not be greater than today");
    else if (startDate > endDate)
      toast.error("Start date can not be greater than End date");
    else {
      // if (endDate?.split("T")[0] > getCurrentDate())
      //   setEndDate(getCurrentDate() + getCurrentTime());
      setGetApiQuery(
        `gymId=${userInfo.gymId._id}&createdAt[gte]=${startDate}T00:00:00.125Z&createdAt[lte]=${endDate}T23:59:59.125Z&sort=-createdAt`
      );
      dispatch(
        setCredentials({
          ...userInfo,
          wsMessageStartDate: startDate,
          wsMessageEndDate: endDate,
        })
      );
    }
  };

  const navigate = useNavigate();
  const handleSendPromotional = () => {
    navigate("/promotionalMessage");
  };

  useEffect(() => {
    refetch();
    const todaysMessages = isLoading ? [] : wsMessages ? wsMessages : [];
    setTodaysMessages(todaysMessages);
    setMessagesToList(todaysMessages);
  }, [setTodaysMessages, wsMessages, refetch]);

  const handleFilterSelecttion = (val) => {
    setMessagesToList(val);
  };

  const exportToExcel = () => {
    if (error) {
      toast.error(
        "No Payments available to Download for the selected Date range"
      );
    } else {
      const formattedData = wsMessages?.map((msg) => ({
        "Gym Id": msg.gymId,
        "Promotion Name": msg.promotionName,
        "Created BY": msg.associateId,
        "Promotion Date":
          msg?.createdAt.toString().split("T")[0] +
          " " +
          msg?.createdAt.toString().split("T")[1].split(".")[0],
        "Message Status": msg?.isMessageSent === true ? "Sent" : "Not Sent Yet",
        Message: msg.promotionMessage,
        "Recipient Mobiles": msg.recipientMobiles.toString(),
      }));

      const workSheet = XLSX.utils.json_to_sheet(formattedData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Users List");
      XLSX.writeFile(workBook, "WhatsApp Ads History.xlsx");
    }
  };

  return (
    <>
      <Button
        className={`${userInfo.themeColor}ThemeHeaderTop`}
        onClick={handleSendPromotional}
      >
        Back
      </Button>
      <br />
      <br />
      <label>
        <strong>WhatsApp Ads List </strong>
      </label>{" "}
      <Row
        className="mb-1"
        style={{
          justifyContent: "center",
          margin: "auto",
          width: "95%",
          alignItems: "center",
        }}
      >
        <Col xs={6} md="auto" sm={6}>
          <Form.Group controlId="visitorMobile">
            <Form.Control
              type="date"
              placeholder="2023-11-23"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            ></Form.Control>{" "}
          </Form.Group>
        </Col>
        <Col xs={6} md="auto" sm={6}>
          <Form.Group controlId="visitorMobile">
            <Form.Control
              type="date"
              placeholder="2023-11-27"
              value={endDate?.split("T")[0]}
              onChange={(e) => setEndDate(e.target.value)}
            ></Form.Control>{" "}
          </Form.Group>
        </Col>
        <Col xs={12} md="auto" sm={6} className="d-flex justify-content-center">
          <Form.Group className="my-1" controlId="gymType">
            <Button
              className={`${userInfo.themeColor}ThemeHeaderTop`}
              onClick={handleGetUsers}
            >
              Get Messages
            </Button>
          </Form.Group>
        </Col>
        <Col xs={6} md="auto" sm={6} className="d-flex justify-content-center">
          <Button
            variant="primary"
            onClick={() => handleFilterSelecttion(todaysMessages)}
          >
            Total<strong> {todaysMessages?.length}</strong>
          </Button>{" "}
        </Col>
        {!userInfo?.isMobileScreen && (
          <Col
            xs={6}
            md="auto"
            sm={6}
            className="d-flex justify-content-center"
          >
            <Button onClick={exportToExcel} variant="success">
              Download History
            </Button>
          </Col>
        )}
      </Row>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message>{error?.data?.error}</Message>
      ) : messagesToList ? (
        <div style={{ height: "100vh" }}>
          <div className="tableContainer">
            <Table
              striped
              bordered
              hover
              responsive="sm"
              className="table-custom"
            >
              <thead className={`${userInfo.themeColor}ThemeThead`}>
                <tr>
                  <th style={{ whiteSpace: "nowrap" }}>Name</th>
                  <th style={{ whiteSpace: "nowrap" }}>Created By</th>
                  <th style={{ whiteSpace: "nowrap" }}>Date</th>
                  <th>Status</th>
                  <th>Message</th>
                  <th>Mobile Numbers</th>
                  {/* <th>Message</th> */}
                </tr>
              </thead>
              <tbody className={`${userInfo.themeColor}ThemeTbody`}>
                {messagesToList?.map((msg) => (
                  <tr key={msg._id}>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {msg?.promotionName}
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>{msg?.associateId}</td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {msg?.createdAt.toString().split("T")[0]}&nbsp;
                      {msg?.createdAt.toString().split("T")[1].split(".")[0]}
                    </td>
                    {/* <td style={{ whiteSpace: "nowrap" }}>
                      {msg?.isMessageSent === true ? "Sent" : "Not Sent Yet"}
                    </td> */}
                    <td>
                      {msg?.isMessageSent === true ? "Sent" : "Not Sent Yet"}
                    </td>
                    <td>
                      <div
                        style={{
                          maxWidth: "300px",
                          whiteSpace:
                            expandedMessageId === msg._id ? "normal" : "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <p>{msg.promotionMessage}</p>
                      </div>
                      <button
                        onClick={() => toggleMessage(msg._id)}
                        style={{
                          border: "none",
                          background: "none",
                          color: "blue",
                          cursor: "pointer",
                        }}
                      >
                        {expandedMessageId === msg._id
                          ? "Show Less"
                          : "Show More"}
                      </button>
                    </td>
                    <td>
                      <div
                        style={{
                          maxWidth: "300px",
                          whiteSpace:
                            expandedMessageId === msg._id ? "normal" : "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <p>
                          {msg?.recipientMobiles
                            .toString()
                            .replace(/[,]/g, ", ")}
                        </p>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default WsMessageAdsListScreen;
