import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Table, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import * as XLSX from "xlsx"; // Importing xlsx library
import jsPDF from "jspdf"; // Importing jsPDF library
import "jspdf-autotable"; // Importing jsPDF Autotable plugin
import { FaSort } from "react-icons/fa";

import Loader from "../components/Loader";
import Message from "../components/Message";
import { useGetPaymentsQuery } from "../slices/paymentSlice";
import { getCurrentDate } from "../utils/getCurrentDate";
import { useGetRenewalsQuery } from "../slices/renewalSlice";
import { useGetUsersQuery } from "../slices/usersSlice";
import { setCredentials } from "../slices/authSlice";
import preferredLanguage from "../assets/preferredLanguage.json";

const PaymentListScreen = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const selectedLanguage = userInfo?.preferredLanguage
    ? userInfo?.preferredLanguage
    : "English";
  const [startDate, setStartDate] = useState(getCurrentDate());

  const [endDate, setEndDate] = useState(getCurrentDate());
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "asc",
  });

  // const [startDate, setStartDate] = useState(
  //   new Date("2024-01-13").toISOString().split("T")[0]
  // );

  // const [endDate, setEndDate] = useState(
  //   new Date("2024-01-13").toISOString().split("T")[0]
  // );

  const [getApiQuery, setGetApiQuery] = useState(
    `?gymId=${userInfo.gymId._id}&paidDate[gte]=${startDate}T00:00:00.000Z&paidDate[lte]=${endDate}T23:59:59.000Z`
  );

  const [renewalQuery, setRenewalQuery] = useState(
    `?gymId=${userInfo.gymId._id}&renewedDate[gte]=${startDate}T00:00:00.000Z&renewedDate[lte]=${endDate}T23:59:59.000Z`
  );

  const [usersQuery, setUsersQuery] = useState(
    `?gymId=${userInfo.gymId._id}&pendingAmount[gt]=0`
  );

  const dispatch = useDispatch();

  const {
    data: payments,
    isLoading: paymentsLoading,
    error: paymentsError,
    refetch: paymentsRefetch,
  } = useGetPaymentsQuery({
    query: getApiQuery,
    token: userInfo ? userInfo.token : "Bearer a",
  });

  const {
    data: renewals,
    isLoading: renewalsLoading,
    error: renewalError,

    refetch: renewalsRefetch,
  } = useGetRenewalsQuery({
    query: renewalQuery,
    token: userInfo ? userInfo.token : "Bearer a",
  });

  const {
    data: users,
    isLoading: usersLoading,

    refetch: usersRefetch,
  } = useGetUsersQuery({
    query: usersQuery,
    token: userInfo ? userInfo.token : "Bearer a",
  });

  const [paymentsToList, setPaymentsToList] = useState(
    paymentsLoading ? [] : payments ? payments : []
  );
  const [allPayments, setAllPayments] = useState(
    paymentsLoading ? [] : payments ? payments : []
  );
  const [allSubscriptions, setAllSubscriptions] = useState([]);
  const [renewedUsers, setRenewedUsers] = useState([]);
  const [newUsers, setNewUsers] = useState([]);
  const [pendingPayments, setPendingPayments] = useState([]);
  const [pendingPaymentsSelected, setPendingPaymentsSelected] = useState(false);

  // let totalAmount = 0;
  // paymentsToList.map((pl) => {
  //   return (totalAmount = totalAmount + pl.paidAmount);
  // });

  const handleGetPayments = async () => {
    if (startDate > getCurrentDate())
      toast.error("Start date can not be greater than today");
    else if (startDate > endDate)
      toast.error("Start date can not be greater than End date");
    else {
      // if (endDate?.split("T")[0] > getCurrentDate())
      //   setEndDate(getCurrentDate() + getCurrentTime());
      // setEndDate(getCurrentDate() + getCurrentTime());
      setGetApiQuery(
        `?gymId=${userInfo.gymId._id}&paidDate[gte]=${startDate}T00:00:00.000Z&paidDate[lte]=${endDate}T23:59:59.000Z`
      );
      setRenewalQuery(
        `?gymId=${userInfo.gymId._id}&renewedDate[gte]=${startDate}T00:00:00.000Z&renewedDate[lte]=${endDate}T23:59:59.000Z`
      );
      dispatch(
        setCredentials({
          ...userInfo,
          paymentsStartDate: startDate,
          paymentsEndDate: endDate,
        })
      );
    }
  };

  const [initApiRequestAtIntervals, setInitApiRequestAtIntervals] = useState(1);
  setInterval(() => {
    setInitApiRequestAtIntervals(Date.now());
  }, 1200000);

  useEffect(() => {
    paymentsRefetch();
  }, [paymentsRefetch, initApiRequestAtIntervals]);

  useEffect(() => {
    renewalsRefetch();
    paymentsRefetch();
    usersRefetch();
    const allPays = paymentsLoading ? [] : payments ? payments : [];
    const sortedPayments =
      sortConfig?.key !== "firstName"
        ? [...allPays]?.sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
              return sortConfig?.direction === "asc" ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
              return sortConfig?.direction === "asc" ? 1 : -1;
            }
            return 0;
          })
        : [...allPays]?.sort((a, b) => {
            if (
              a[sortConfig.key]?.toString().toLowerCase() <
              b[sortConfig.key]?.toString().toLowerCase()
            ) {
              return sortConfig?.direction === "asc" ? -1 : 1;
            }
            if (
              a[sortConfig.key]?.toString().toLowerCase() >
              b[sortConfig.key]?.toString().toLowerCase()
            ) {
              return sortConfig?.direction === "asc" ? 1 : -1;
            }
            return 0;
          });

    setPaymentsToList(sortedPayments);
    setAllPayments(allPays);

    const allSubs = renewalsLoading ? [] : renewals ? renewals : [];
    setAllSubscriptions(allSubs);

    const reneUsers = renewalsLoading
      ? []
      : renewals
      ? renewals.filter((pl) => pl?.compositeId?.joinedDate < pl?.renewedDate)
      : [];
    setRenewedUsers(reneUsers);
    const newUsrs = renewalsLoading
      ? []
      : renewals
      ? renewals.filter((pl) => pl?.compositeId?.joinedDate === pl?.renewedDate)
      : [];
    setNewUsers(newUsrs);

    const pendingPaymentUsers = usersLoading ? [] : users ? users : [];
    setPendingPayments(pendingPaymentUsers);
  }, [payments, renewals, users, sortConfig]);

  const handleFilterSelecttion = (val) => {
    setPaymentsToList(val);
    setPendingPaymentsSelected(false);
  };

  const handlePendingPaymentSelection = (val) => {
    setPaymentsToList(val);
    setPendingPaymentsSelected(true);
  };

  useEffect(() => {
    if (paymentsError?.data?.error) {
      setPaymentsToList([]);
      setAllPayments([]);
    }
  }, [paymentsError]);

  useEffect(() => {
    if (renewalError?.data?.error) {
      setAllSubscriptions([]);
      setRenewedUsers([]);
      setNewUsers([]);
    }
  }, [renewalError]);

  const exportToExcel = () => {
    if (paymentsError) {
      toast.error(
        "No Payments available to Download for the selected Date range"
      );
    } else {
      const formattedData = payments?.map((user) => ({
        "User Name": user.compositeId
          ? user.compositeId.firstName
          : user.firstName,
        "User ID": user.userId,
        "Subscription Amount": user.subscriptionAmount,
        "Paid Amount": user.paidAmount,
        "Transaction ID": user.transactionId,
        "Paid Date": user.paidDate
          ? user.paidDate.split("T")[0]
          : user.lastPaidDate.split("T")[0],
        ...(!pendingPaymentsSelected && { AddedBy: user.associateId }), // Include associate ID if not in pending selection
      }));

      const totalPaidAmount = payments?.reduce(
        (total, user) => total + Number(user.paidAmount),
        0
      );

      formattedData.push({
        "User Name": "",
        "User ID": "",
        "Subscription Amount": "Total",
        "Paid Amount": totalPaidAmount,
        "Transaction ID": "",
        "Paid Date": "",
        AddedBy: "",
      });

      const workSheet = XLSX.utils.json_to_sheet(formattedData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Payments");
      XLSX.writeFile(
        workBook,
        "Payments_from " + startDate + " to " + endDate + ".xlsx"
      );
    }
  };

  // Function to export data to PDF
  const exportToPDF = () => {
    if (paymentsError) {
      toast.error(
        "No Payments available to Download for the selected Date range"
      );
    } else {
      const doc = new jsPDF();
      doc.text("Payments from " + startDate + " to " + endDate, 20, 10);
      doc.autoTable({
        startY: 20,
        head: [
          [
            "User Name",
            "User ID",
            "Subscription Amount",
            "Paid Amount",
            "Transaction ID",
            "Paid Date",
            "AddedBy",
          ],
        ],
        body: [
          ...payments?.map((user) => [
            user.compositeId ? user.compositeId.firstName : user.firstName,
            user.userId,
            user.subscriptionAmount,
            user.paidAmount,
            user.transactionId,
            user.paidDate
              ? user.paidDate.split("T")[0]
              : user.lastPaidDate.split("T")[0],
            user.associateId, // Include associate ID if not in pending selection
          ]),
          [
            "",
            "",
            { content: "Total", styles: { fontStyle: "bold" } }, // "Total" in bold
            {
              content: payments?.reduce(
                (total, user) => total + Number(user.paidAmount),
                0
              ),
              styles: { fontStyle: "bold" }, // Total amount in bold
            },
            "",
            "",
            "",
          ],
        ],
      });
      doc.save("Payments_from " + startDate + " to " + endDate + ".pdf");
    }
  };

  const handleSort = (columnKey) => {
    let direction = "asc";
    if (sortConfig.key === columnKey && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key: columnKey, direction });
  };

  return (
    <>
      <label>
        <strong>
          {" "}
          {preferredLanguage[selectedLanguage]?.paymentsListPage.paymentsList}
        </strong>
      </label>
      <Row
        className="my-1"
        style={{
          width: "90%",
          margin: "auto",
          paddingBottom: "0.5%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Col xs={6} md="auto" sm={6}>
          <Form.Group controlId="visitorMobile">
            <Form.Control
              type="date"
              placeholder="2023-11-23"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            ></Form.Control>
          </Form.Group>
        </Col>
        <Col xs={6} md="auto" sm={6}>
          <Form.Group controlId="visitorMobile">
            <Form.Control
              type="date"
              placeholder="2023-11-23"
              value={endDate?.split("T")[0]}
              onChange={(e) => setEndDate(e.target.value)}
            ></Form.Control>
          </Form.Group>
        </Col>
        <Col xs={6} md="auto" sm={6} className="d-flex justify-content-center">
          <Form.Group as={Row} className="my-1" controlId="visitorMobile">
            <Button
              className={`${userInfo.themeColor}ThemeHeaderTop`}
              onClick={handleGetPayments}
            >
              Get Payments
            </Button>
          </Form.Group>
        </Col>
        {!userInfo?.isMobileScreen && (
          <>
            <Col
              xs={6}
              md="auto"
              sm={6}
              className="d-flex justify-content-center"
            >
              <Button onClick={exportToExcel} variant="success">
                {
                  preferredLanguage[selectedLanguage]?.paymentsListPage
                    .downloadExcel
                }
              </Button>
            </Col>
            <Col
              xs={6}
              md="auto"
              sm={6}
              className="d-flex justify-content-center"
            >
              <Button onClick={exportToPDF} variant="danger">
                {
                  preferredLanguage[selectedLanguage]?.paymentsListPage
                    .downloadPDF
                }
              </Button>
            </Col>
          </>
        )}
      </Row>
      <Row
        className="mb-1"
        style={{
          width: "95%",
          margin: "auto",
          paddingBottom: "1%",
          justifyContent: "center",
        }}
      >
        <Button
          className="marginPointEight sixteenWidth buttonGreen numbersButtonPayementsList"
          onClick={() => handleFilterSelecttion(allSubscriptions)}
        >
          {preferredLanguage[selectedLanguage]?.paymentsListPage.total}{" "}
          <strong>
            {allSubscriptions?.reduce((accumulator, obj) => {
              return accumulator + obj.subscriptionAmount;
            }, 0)}
          </strong>
          {/* <Badge>{allSubscriptions?.length}</Badge> */}
        </Button>
        <Button
          className="marginPointEight sixteenWidth buttonTeal numbersButtonPayementsList"
          onClick={() => handleFilterSelecttion(renewedUsers)}
        >
          {preferredLanguage[selectedLanguage]?.paymentsListPage.renewal}{" "}
          <strong>
            {renewedUsers?.reduce((accumulator, obj) => {
              return accumulator + obj.subscriptionAmount;
            }, 0)}
          </strong>
          {/* <Badge>{renewedUsers?.length}</Badge> */}
        </Button>
        <Button
          className=" marginPointEight sixteenWidth buttonOrchid numbersButtonPayementsList"
          onClick={() => handleFilterSelecttion(newUsers)}
        >
          {preferredLanguage[selectedLanguage]?.paymentsListPage.new}{" "}
          <strong>
            {newUsers?.reduce((accumulator, obj) => {
              return accumulator + obj.subscriptionAmount;
            }, 0)}
          </strong>
          {/* <Badge>{newUsers?.length}</Badge> */}
        </Button>
        <Button
          className=" marginPointEight sixteenWidth buttonGreenish numbersButtonPayementsList"
          onClick={() => handleFilterSelecttion(allPayments)}
        >
          {preferredLanguage[selectedLanguage]?.paymentsListPage.collected}{" "}
          <strong>
            {allPayments?.reduce((accumulator, obj) => {
              return accumulator + obj.paidAmount;
            }, 0)}
          </strong>
          {/* <Badge>{allPayments?.length}</Badge> */}
        </Button>
        <Button
          className=" marginPointEight sixteenWidth buttonOrange numbersButtonPayementsList"
          onClick={() => handlePendingPaymentSelection(pendingPayments)}
        >
          {preferredLanguage[selectedLanguage]?.paymentsListPage.pending}{" "}
          <strong>
            {pendingPayments?.reduce((accumulator, obj) => {
              return accumulator + obj.pendingAmount;
            }, 0)}
          </strong>
          {/* <Badge>{pendingPayments?.length}</Badge> */}
        </Button>
      </Row>
      {paymentsLoading && renewalsLoading ? (
        <Loader />
      ) : paymentsError ? (
        <Message>{paymentsError?.data?.error}</Message>
      ) : (
        <div style={{ height: "100vh" }}>
          <div className="tableContainer">
            <Table
              striped
              bordered
              hover
              responsive="sm"
              className="table-custom"
            >
              <thead className={`${userInfo.themeColor}ThemeThead`}>
                <tr>
                  <th>Name</th>
                  <th
                    onClick={() => handleSort("userId")}
                    style={{ cursor: "pointer" }}
                  >
                    Id
                    <FaSort />
                  </th>
                  <th
                    onClick={() => handleSort("subscriptionAmount")}
                    style={{ cursor: "pointer" }}
                  >
                    Subs Amount
                    <FaSort />
                  </th>
                  <th
                    onClick={() => handleSort("paidAmount")}
                    style={{ cursor: "pointer" }}
                  >
                    Paid Amount
                    <FaSort />
                  </th>
                  <th>TransactionID</th>
                  <th
                    onClick={() => handleSort("paidDate")}
                    style={{ cursor: "pointer" }}
                  >
                    Date
                    <FaSort />
                  </th>

                  {pendingPaymentsSelected ? (
                    <></>
                  ) : (
                    <th
                      onClick={() => handleSort("associateId")}
                      style={{ cursor: "pointer" }}
                    >
                      Added By
                      <FaSort />
                    </th>
                  )}
                  {pendingPaymentsSelected ? <th>Pending</th> : <></>}
                </tr>
              </thead>
              {paymentsToList ? (
                <tbody className={`${userInfo.themeColor}ThemeTbody`}>
                  {paymentsToList?.map((user) => (
                    <tr>
                      <td>
                        <Link to={`/userPayments/${user.userId}`}>
                          {user.compositeId
                            ? user.compositeId.firstName
                            : user.firstName}
                        </Link>
                      </td>
                      <td> {user.userId} </td>
                      <td> {user.subscriptionAmount} </td>
                      <td> {user.paidAmount}</td>
                      <td> {user.transactionId}</td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        {user.paidDate
                          ? user.paidDate?.split("T")[0]
                          : user.lastPaidDate?.split("T")[0]}
                      </td>
                      {pendingPaymentsSelected ? (
                        <td
                          style={{
                            color: user.pendingAmount > 0 ? "red" : "",
                          }}
                        >
                          {user.pendingAmount}
                        </td>
                      ) : (
                        <></>
                      )}
                      {pendingPaymentsSelected ? (
                        <></>
                      ) : (
                        <td> {user?.associateId} </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              ) : (
                <Message>No Payments Available for the selected Period</Message>
              )}
            </Table>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentListScreen;
