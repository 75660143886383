import { useSelector } from "react-redux";
import QRCodeGenerator from "../utils/createQRcode";
import { Row, Col, Button } from "react-bootstrap";
import html2canvas from "html2canvas";
import preferredLanguage from "../assets/preferredLanguage.json";

const QrCodeScreen = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const qrContent = `https://mygym.zpotter.in/attendanceFromMobile/${userInfo.gymId._id}`;
  const selectedLanguage = userInfo?.preferredLanguage
    ? userInfo?.preferredLanguage
    : "English";

  const downloadPageAsImage = () => {
    const element = document.getElementById("qrPageContent");
    html2canvas(element, { scale: 2 }).then((canvas) => {
      const image = canvas.toDataURL("image/png"); // Convert to image
      const link = document.createElement("a");
      link.href = image;
      link.download = "QrPage.png"; // Name of the downloaded file
      link.click();
    });
  };

  return (
    <div style={{ backgroundColor: "white", height: "100vh" }}>
      <Row>
        <Col md={3}></Col>
        <Col md={6}>
          {!userInfo?.isMobileScreen && (
            <Button
              className="marginPointOne sixteenWidth buttonTeal numbersButtonDashboard"
              onClick={downloadPageAsImage}
              style={{
                marginTop: "10px",
                backgroundColor: "#007bff",
                color: "white",
                border: "none",
                borderRadius: "5px",
                width: "40%",
              }}
            >
              Download QR Code
            </Button>
          )}
          <Row
            id="qrPageContent"
            className="mb-1"
            style={{
              justifyItems: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              margin: "auto",
              paddingBottom: "1%",
              justifyContent: "center",
            }}
          >
            <h4 style={{ color: "black" }}>
              {preferredLanguage[selectedLanguage]?.attendanceQrCode}
            </h4>
            <QRCodeGenerator
              content={qrContent}
              style={{ width: "500px", height: "500px" }}
            />
            <br />
            <br />
            <div>
              {preferredLanguage[selectedLanguage]?.QrAtt?.split("\n").map(
                (m, index) => (
                  <div
                    key={index}
                    style={{ color: "black", fontSize: "1.5rem" }}
                  >
                    {m}
                  </div>
                )
              )}
            </div>
          </Row>
        </Col>
        <Col md={3}></Col>
      </Row>
    </div>
  );
};

export default QrCodeScreen;
