import React, { useState, useEffect } from "react";
import { Row, Col, ListGroup, Card, Button, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../components/Loader";
import Message from "../components/Message";
import {
  useGetMyGymQuery,
  useUpdateUsersTokenMutation,
} from "../slices/gymSlice";
import VerticallyCenteredModal from "../components/SubmitModal";
import { toast } from "react-toastify";
import GymProfileEditScreen from "./GymProfileEditScreen";
import GymPaymentUpdateScreen from "./GymPaymentUpdateScreen";
import { setCredentials } from "../slices/authSlice";
import {
  useGetMyGymMetaQuery,
  useUpdateGymMetaMutation,
  useUpdateWsSettingsMutation,
} from "../slices/wsGymMetaSlice";

const GymProfileScreen = () => {
  const { userInfo } = useSelector((state) => state.auth);

  const [editable, setEditable] = useState(false);
  const [updatePayment, setUpdatePayment] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const dispatch = useDispatch();
  const [updateGym] = useUpdateUsersTokenMutation();
  const [updateWsSettings] = useUpdateWsSettingsMutation();
  const [customePackages, setCustomePackages] = useState();

  const [wsSettings, setWsSettings] = useState({
    wsRenewalReminder: { enabled: false, days: 3 },
    wsInActiveReminder: { enabled: false, days: 7 },
    wsSubscriptionNotification: { enabled: false },
    wsBirthdayNotification: { enabled: false },
  });

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setWsSettings((prevSettings) => ({
      ...prevSettings,
      [name]: { ...prevSettings[name], enabled: checked },
    }));
  };

  const handleDaysChange = (e) => {
    const { name, value } = e.target;
    setWsSettings((prevSettings) => ({
      ...prevSettings,
      [name]: { ...prevSettings[name], days: value },
    }));
  };

  let {
    data: myGymDetails,
    isLoading,
    error,
    refetch,
  } = useGetMyGymQuery({
    token: userInfo.token,
  });

  let {
    data: myGymMetaDetails,
    isLoading: isMetaLoading,
    error: errorInMeta,
    refetch: refetchMeta,
  } = useGetMyGymMetaQuery({
    token: userInfo.token,
  });

  useEffect(() => {
    refetchMeta();
    const gymMeta = errorInMeta
      ? {}
      : isMetaLoading
      ? {}
      : myGymMetaDetails
      ? myGymMetaDetails
      : {};

    setWsSettings({
      wsRenewalReminder: {
        enabled: gymMeta?.wsRenewalReminder
          ? gymMeta?.wsRenewalReminder
          : false,
        days: gymMeta?.wsRenewalReminderIndays
          ? gymMeta?.wsRenewalReminderIndays
          : 3,
      },
      wsInActiveReminder: {
        enabled: gymMeta?.wsInActiveReminder
          ? gymMeta?.wsInActiveReminder
          : false,
        days: gymMeta?.wsInActiveReminderInDays
          ? gymMeta?.wsInActiveReminderInDays
          : 7,
      },
      wsSubscriptionNotification: {
        enabled: gymMeta?.wsSubscriptionNotification
          ? gymMeta?.wsSubscriptionNotification
          : false,
      },
      wsBirthdayNotification: {
        enabled: gymMeta?.wsBirthdayNotification
          ? gymMeta?.wsBirthdayNotification
          : false,
      },
    });
  }, [myGymMetaDetails, refetchMeta]);

  useEffect(() => {
    refetch();
  }, [refetch, editable, updatePayment]);

  useEffect(() => {
    refetch();
    const gymDetail = isLoading ? [] : myGymDetails ? myGymDetails : [];
    // console.log(gymDetail.customPackage);
    // console.log(Object.keys(gymDetail.customPackage));
    setCustomePackages(gymDetail.customPackage);
    dispatch(
      setCredentials({
        ...userInfo,
        gymId: {
          name: gymDetail.name,
          subscriptionType: gymDetail.subscriptionType,
          _id: gymDetail._id,
        },
      })
    );
  }, [refetch, myGymDetails]);

  const handleEditStatus = () => {
    setEditable(false);
  };

  const handleUpdatePayment = () => {
    setUpdatePayment(false);
  };

  const submitEdit = async () => {
    try {
      await updateGym({
        gymId: userInfo.gymId._id,
        token: userInfo.token,
      }).unwrap();

      toast.success("URL is updated");
    } catch (e) {
      toast.error(e?.data?.error || e.data);
    }
  };

  const submitWhatsAppMeta = async () => {
    // console.log("wsSettings");
    // console.log(wsSettings);
    if (
      Number(wsSettings?.wsRenewalReminder?.days) < 3 ||
      Number(wsSettings?.wsInActiveReminder?.days) < 3
    ) {
      toast.error("Can not update less than 3 days");
    } else {
      try {
        await updateWsSettings({
          gymId: userInfo?.gymId?._id,
          token: userInfo?.token,
          payload: {
            wsRenewalReminder: wsSettings?.wsRenewalReminder?.enabled,
            wsRenewalReminderIndays: wsSettings?.wsRenewalReminder?.days,
            wsInActiveReminder: wsSettings?.wsInActiveReminder?.enabled,
            wsInActiveReminderInDays: wsSettings?.wsInActiveReminder?.days,
            wsSubscriptionNotification:
              wsSettings?.wsSubscriptionNotification?.enabled,
            wsBirthdayNotification: wsSettings?.wsBirthdayNotification?.enabled,
          },
        }).unwrap();
        toast.success("WhatsApp Notifications Settings updated");
      } catch (e) {
        toast.error(e?.data?.error || e.data);
      }
    }
  };

  const handleSubmit = async (user) => {
    setModalShow1(true);
  };

  return (
    <>
      <VerticallyCenteredModal
        title="Are you sure want to Change URL ?"
        show={modalShow}
        size="sm"
        onHide={() => setModalShow(false)}
        onSubmit={submitEdit}
      />

      <VerticallyCenteredModal
        title="Are you sure want to update WhatsApp Notifications ?"
        show={modalShow1}
        size="sm"
        onHide={() => setModalShow1(false)}
        onSubmit={submitWhatsAppMeta}
      />

      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message>{error?.data?.error}</Message>
      ) : (
        <>
          {" "}
          {editable ? (
            <GymProfileEditScreen
              associate={myGymDetails}
              editable={handleEditStatus}
            />
          ) : updatePayment ? (
            <GymPaymentUpdateScreen
              associate={myGymDetails}
              updatePayment={handleUpdatePayment}
            />
          ) : (
            <>
              <h4>Gym Profile : {myGymDetails?._id} </h4>
              <Row style={{ margin: "2%" }}>
                {userInfo.role === "owner" ||
                userInfo.role === "Super Admin" ? (
                  <Button
                    className=" marginPointEight sixteenWidth buttonPeach twoButtonInRow"
                    onClick={(e) => setEditable(true)}
                  >
                    Edit
                  </Button>
                ) : (
                  <></>
                )}

                <Button
                  className=" marginPointEight sixteenWidth buttonTeal twoButtonInRow"
                  onClick={(e) => setUpdatePayment(true)}
                >
                  Update Payment
                </Button>
              </Row>

              <Row>
                <Col md={5}>
                  <Card>
                    <ListGroup>
                      <ListGroup.Item>
                        {
                          <Row>
                            <Col> Gym Nme : </Col>
                            <Col> {myGymDetails.name}</Col>
                          </Row>
                        }
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col> Owner Name : </Col>
                          <Col> {myGymDetails.ownerName}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col> email : </Col>
                          <Col> {myGymDetails.email}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col> gymType : </Col>
                          <Col> {myGymDetails.gymType}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {
                          <Row>
                            <Col> Joined Date : </Col>
                            <Col> {myGymDetails.createdAt?.split("T")[0]}</Col>
                          </Row>
                        }
                      </ListGroup.Item>

                      <ListGroup.Item>
                        <Row>
                          <Col> Contact Number: </Col>
                          <Col> {myGymDetails.primaryContact}</Col>
                        </Row>
                      </ListGroup.Item>
                    </ListGroup>
                  </Card>
                </Col>
                <Col md={5}>
                  <Card>
                    <ListGroup>
                      <ListGroup.Item>
                        <Row>
                          <Col> Alternate Number : </Col>
                          <Col> {myGymDetails.secondaryContacts}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col> Pincode : </Col>
                          <Col> {myGymDetails.pincode}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {
                          <Row>
                            <Col> Address : </Col>
                            <Col> {myGymDetails.address}</Col>
                          </Row>
                        }
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {
                          <Row>
                            <Col> subscriptionType : </Col>
                            <Col> {myGymDetails.subscriptionType}</Col>
                          </Row>
                        }
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {
                          <Row>
                            <Col> Due Amount : </Col>
                            <Col>
                              {" "}
                              {myGymDetails.dueAmount}{" "}
                              <a
                                style={{
                                  color: "red",
                                  textDecoration: "none",
                                }}
                                href="https://rzp.io/rzp/2KHY9QF7"
                              >
                                <b> click to Pay</b>
                              </a>
                            </Col>
                          </Row>
                        }
                      </ListGroup.Item>
                    </ListGroup>
                  </Card>
                </Col>
              </Row>
              <br />
              <br />
              {isMetaLoading ? (
                <Loader />
              ) : (
                <Row>
                  <h5>Select WhatsApp Notifications</h5>
                  <Col md={5}>
                    <Card>
                      <ListGroup>
                        <ListGroup.Item
                          className={`${userInfo.themeColor}ThemeHeaderTop`}
                        >
                          <Row>
                            <Col>
                              <h6>Message Type</h6>
                            </Col>
                            <Col>
                              {" "}
                              <h6>Days</h6>
                            </Col>
                          </Row>
                        </ListGroup.Item>
                        <ListGroup.Item
                          className={`${userInfo.themeColor}ThemeHeaderTop`}
                        >
                          <Row>
                            <Col>
                              <Form.Check
                                type="checkbox"
                                label="Renewal Reminder"
                                name="wsRenewalReminder"
                                checked={wsSettings.wsRenewalReminder.enabled}
                                onChange={handleCheckboxChange}
                              />
                            </Col>
                            <Col>
                              <Form.Control
                                type="number"
                                placeholder="enter days"
                                name="wsRenewalReminder"
                                value={wsSettings?.wsRenewalReminder?.days}
                                onChange={handleDaysChange}
                                disabled={
                                  !wsSettings?.wsRenewalReminder?.enabled
                                } // Disable if the checkbox is not checked
                              />
                            </Col>
                          </Row>
                        </ListGroup.Item>
                        <ListGroup.Item
                          className={`${userInfo.themeColor}ThemeHeaderTop`}
                        >
                          <Row>
                            <Col>
                              <Form.Check
                                type="checkbox"
                                label="Inactive Reminder"
                                name="wsInActiveReminder"
                                checked={
                                  wsSettings?.wsInActiveReminder?.enabled
                                }
                                onChange={handleCheckboxChange}
                              />
                            </Col>
                            <Col>
                              <Form.Control
                                type="number"
                                placeholder="enter days"
                                name="wsInActiveReminder"
                                value={wsSettings?.wsInActiveReminder?.days}
                                onChange={handleDaysChange}
                                disabled={
                                  !wsSettings?.wsInActiveReminder?.enabled
                                }
                              />
                            </Col>
                          </Row>
                        </ListGroup.Item>
                        <ListGroup.Item
                          className={`${userInfo.themeColor}ThemeHeaderTop`}
                        >
                          <Row>
                            <Col>
                              <Form.Check
                                type="checkbox"
                                label="Subscription Notification"
                                name="wsSubscriptionNotification"
                                checked={
                                  wsSettings?.wsSubscriptionNotification
                                    ?.enabled
                                }
                                onChange={handleCheckboxChange}
                              />
                            </Col>
                          </Row>
                        </ListGroup.Item>{" "}
                        <ListGroup.Item
                          className={`${userInfo.themeColor}ThemeHeaderTop`}
                        >
                          <Row>
                            <Col>
                              <Form.Check
                                type="checkbox"
                                label="Birthday Wish Message"
                                name="wsBirthdayNotification"
                                checked={
                                  wsSettings?.wsBirthdayNotification?.enabled
                                }
                                onChange={handleCheckboxChange}
                              />
                            </Col>
                          </Row>{" "}
                        </ListGroup.Item>
                      </ListGroup>
                      <br />
                      <Row
                        sm="4"
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          margin: "2rem 0rem",
                        }}
                      >
                        <Button
                          className={`${userInfo.themeColor}ThemeHeaderTop`}
                          type="submit"
                          disabled={isLoading}
                          variant="primary"
                          onClick={handleSubmit}
                          style={{ maxWidth: "7rem" }}
                        >
                          {" "}
                          Submit
                        </Button>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default GymProfileScreen;
