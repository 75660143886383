import { Button, Form, Row, Col } from "react-bootstrap";

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { useUpdateMyDetailsMutation } from "../slices/associatesSlice";
import { getCurrentDate } from "../utils/getCurrentDate";
import FieldWarning from "../components/FieldWarning";
import VerticallyCenteredModal from "../components/SubmitModal";

const AssociateRegisterScreen = ({ associate, editable }) => {
  // const [regCode, setRegCode] = useState();
  const { userInfo } = useSelector((state) => state.auth);
  const [associateId, setAssociateId] = useState(associate.associateId);
  const [firstName, setFirstName] = useState(associate.firstName);
  const [lastName, setLastName] = useState(associate.lastName);
  const [gender, setGender] = useState(associate.gender);

  const [email, setEmail] = useState(associate.email);
  const [contactNumber, setConatctNumber] = useState(associate.mobileNumber);
  const [alternateNumber, setAlternateNumber] = useState(
    associate.alternateNumber
  );
  const [address, setAddress] = useState(associate.address);
  const [pincode, setPincode] = useState(associate.pincode);
  const [aadharNumber, setAadharNumber] = useState(associate.aadharNumber);
  const [panNumber, setPanNumber] = useState(associate.panNumber);

  const [dateOfBirth, setDateOfBirth] = useState(
    associate.dateOfBirth?.split("T")[0]
  );
  const [joinedDate, setJoinedDate] = useState(
    associate.joinedDate?.split("T")[0]
  );
  const [modalShow, setModalShow] = useState(false);

  const [firstNamePresent, setFirstNamePresent] = useState(false);
  const [lastNamePresent, setLastNamePresent] = useState(false);
  const [genderPresent, setGenderPresent] = useState(false);
  const [mobileNumberPresent, setMobileNumberPresent] = useState(false);
  const [alternateNumberPresent, setAlternateNumberPresent] = useState(false);
  const [emailPresent, setEmailPresent] = useState(false);
  const [aadharNumberPresent, setAadharNumberPresent] = useState(false);
  const [panNumberPresent, setPanNumberPresent] = useState(false);
  const [joinedDatePresent, setJoinedDatePresent] = useState(false);
  const [dateOfBirthPresent, setDateOfBirthPresent] = useState(false);
  const [addressPresent, setAddressPresent] = useState(false);
  const [pincodePresent, setPincodePresent] = useState(false);

  const availableGender = ["Male", "Female", "Other"];
  // const roleIds = { "Super Admin": 1, Admin: 2, Associate: 3, Rookie: 4 };

  const [updateMyDetails, { isLoading }] = useUpdateMyDetailsMutation();

  // const userInfo = useSelector((state) => state.auth);

  // let { search } = useLocation();
  // const ps = new URLSearchParams(search);
  // const redirect = ps.get("redirect") || "/";

  //   useEffect(() => {
  //     if (userInfo) {
  //       navigate(redirect);
  //     }
  //   }, [userInfo, redirect, navigate]);

  useEffect(() => {
    if (firstName?.length > 0) {
      setFirstNamePresent(false);
    }
    if (lastName?.length > 0) {
      setLastNamePresent(false);
    }

    if (gender?.length > 1) {
      setGenderPresent(false);
    }

    if (contactNumber?.length === 10 && contactNumber > 1) {
      setMobileNumberPresent(false);
    }

    if (alternateNumber?.length === 10 || alternateNumber?.length === 0) {
      setAlternateNumberPresent(false);
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailRegex.test(email) || email?.length === 0) {
      setEmailPresent(false);
    }

    if (aadharNumber?.length === 12 || aadharNumber?.length === 0) {
      setAadharNumberPresent(false);
    }

    if (panNumber?.length > 9 || panNumber?.length === 0) {
      setPanNumberPresent(false);
    }

    if (address?.length > 10 || address?.length === 0) {
      setAddressPresent(false);
    }

    if (pincode?.length === 6 || pincode?.length === 0) {
      setPincodePresent(false);
    }

    if (dateOfBirth < getCurrentDate()) {
      setDateOfBirthPresent(false);
    }

    if (joinedDate <= getCurrentDate()) {
      setJoinedDatePresent(false);
    }
  }, [
    firstName,
    lastName,
    gender,
    email,
    aadharNumber,
    dateOfBirth,
    joinedDate,
    address,
    associateId,
    contactNumber,

    pincode,
    alternateNumber,
    panNumber,
  ]);

  const verifyInputFeilds = async () => {
    let fieldValidationSuccess = true;

    if (firstName?.length < 1 || firstName?.length > 100) {
      setFirstNamePresent(true);
      fieldValidationSuccess = false;
    }
    if (lastName?.length > 100) {
      setLastNamePresent(true);
      fieldValidationSuccess = false;
    }

    if (gender?.length < 1) {
      setGenderPresent(true);
      fieldValidationSuccess = false;
    }

    if (contactNumber?.toString().length !== 10 || contactNumber < 1) {
      setMobileNumberPresent(true);
      fieldValidationSuccess = false;
    }
    if (alternateNumber)
      if (alternateNumber?.toString().length !== 10 || alternateNumber < 1) {
        setAlternateNumberPresent(true);
        fieldValidationSuccess = false;
      }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (email)
      if (!emailRegex.test(email) || email?.length > 300) {
        setEmailPresent(true);
        fieldValidationSuccess = false;
      }
    if (aadharNumber)
      if (aadharNumber?.length !== 12 || Number(aadharNumber) < 0) {
        setAadharNumberPresent(true);
        fieldValidationSuccess = false;
      }
    if (panNumber)
      if (panNumber?.length < 8 || panNumber < 1) {
        setPanNumberPresent(true);
        fieldValidationSuccess = false;
      }

    if (address)
      if (address?.length < 10 || address?.length > 1000) {
        setAddressPresent(true);
        fieldValidationSuccess = false;
      }
    if (pincode)
      if (pincode?.toString().length !== 6 || pincode < 1) {
        setPincodePresent(true);
        fieldValidationSuccess = false;
      }

    if (!dateOfBirth || dateOfBirth >= getCurrentDate()) {
      setDateOfBirthPresent(true);
      fieldValidationSuccess = false;
    }

    if (joinedDate > getCurrentDate()) {
      setJoinedDatePresent(true);
      fieldValidationSuccess = false;
    }
    if (!fieldValidationSuccess)
      toast.error(
        "Some of the fields have invalid inputs. Please provide valid details"
      );
    return fieldValidationSuccess;
  };

  const submitHandler = async (e) => {
    // e.preventDefault();
    try {
      const payload = {
        firstName,
        lastName,
        gender,
        mobileNumber: contactNumber,
        alternateNumber,
        joinedDate,
        dateOfBirth,
        email,
        pincode,
        address,
        panNumber,
        aadharNumber,
        token: userInfo.token,
      };
      await updateMyDetails(payload).unwrap();
      editable();
      toast.success("Associate details updated successfuly ");
      // setFirstName("");
      // setLastName("");
      // setGender("Male");
      // setEmail("");
      // setConatctNumber("");
      // setAlternateNumber("");
      // setAddress("");
      // setPincode("");
      // setAadharNumber("");
      // setPanNumber("");
      // setDateOfBirth(getCurrentDate());
      // setJoinedDate(getCurrentDate());
      // navigate("/");
    } catch (e) {
      toast.error(e?.data?.error || e.data);
    }
  };

  const handleCancelButton = () => {
    // navigate(`/profile`);
    editable();
  };

  const handleSubmit = async (user) => {
    if (await verifyInputFeilds()) setModalShow(true);
  };

  return (
    <>
      <VerticallyCenteredModal
        title="Are you sure want to submit this ?"
        size="sm"
        show={modalShow}
        onHide={() => setModalShow(false)}
        onSubmit={submitHandler}
        // setEdit={() => paymentStatus(true)}
      />
      <Row>
        <Col md={6}>
          <Form.Group as={Row} className="my-2" controlId="firstName">
            <Form.Label column="true" sm="3">
              First Name <label style={{ color: "red" }}>*</label> :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="enter first Name"
              ></Form.Control>{" "}
              {firstNamePresent ? (
                <FieldWarning text="First name should have minimum 1 char and max 100 char" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="lastName">
            <Form.Label column="true" sm="3">
              Last Name :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="enter last Name"
              ></Form.Control>
              {lastNamePresent ? (
                <FieldWarning text="Last name can not have more than 100 characters" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="gender">
            <Form.Label column="true" sm="3">
              Select Gender <label style={{ color: "red" }}>*</label> :
            </Form.Label>
            <Col sm="6">
              <Form.Select
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                placeholder="Select Gender"
              >
                {availableGender.map((e) => (
                  <option key={e} value={e}>
                    {e}
                  </option>
                ))}
              </Form.Select>
              {genderPresent ? (
                <FieldWarning text="Please select gender" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="my-2" controlId="contactNumber">
            <Form.Label column="true" sm="3">
              Contact Number <label style={{ color: "red" }}>*</label> :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="text"
                pattern="[0-9]*" // Allow only digits
                value={contactNumber}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                  setConatctNumber(value);
                }}
                placeholder="enter primary contact number"
              />
              {mobileNumberPresent ? (
                <FieldWarning text="Please provide 10 digit mobile number" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="dateOfBirth">
            <Form.Label column="true" sm="3">
              Date Of Birth <label style={{ color: "red" }}>*</label>:
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="date"
                value={dateOfBirth}
                onChange={(e) => setDateOfBirth(e.target.value)}
                placeholder="in format 'YYYY-MM-DD'"
              ></Form.Control>{" "}
              {dateOfBirthPresent ? (
                <FieldWarning text="Please provide valid date of birth" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="joinedDate">
            <Form.Label column="true" sm="3">
              Joined Date <label style={{ color: "red" }}>*</label>:
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="date"
                value={joinedDate}
                onChange={(e) => setJoinedDate(e.target.value)}
                placeholder="in format 'YYYY-MM-DD'"
              ></Form.Control>
              {joinedDatePresent ? (
                <FieldWarning text="Joined date can not be future date" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group as={Row} className="my-2" controlId="email">
            <Form.Label column="true" sm="3">
              Email :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="enter gym or owner's email"
              ></Form.Control>
              {emailPresent ? (
                <FieldWarning text="Please provide valid e-mail" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="my-2" controlId="alternateNumber">
            <Form.Label column="true" sm="3">
              Alternate Contact :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="text"
                pattern="[0-9]*" // Allow only digits
                value={alternateNumber}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                  setAlternateNumber(value);
                }}
                placeholder="enter secondary Contact Number"
              />
              {alternateNumberPresent ? (
                <FieldWarning text="Please provide 10 digit mobile Number" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="address">
            <Form.Label column="true" sm="3">
              Address :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder="enter Gym Address"
              ></Form.Control>
              {addressPresent ? (
                <FieldWarning text="Address should be atleast 10 chars long" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="pincode">
            <Form.Label column="true" sm="3">
              Pincode :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="text"
                pattern="[0-9]*" // Allow only digits
                value={pincode}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                  setPincode(value);
                }}
                placeholder="enter gym pincode"
              />
              {pincodePresent ? (
                <FieldWarning text="Please provide valid 6 digit Pincode" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="panNumber">
            <Form.Label column="true" sm="3">
              PAN Number :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="text"
                value={panNumber}
                onChange={(e) => setPanNumber(e.target.value)}
                placeholder="enter PAN Number"
              ></Form.Control>
              {panNumberPresent ? (
                <FieldWarning text="Please provide valid PAN number" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="aadharNumber">
            <Form.Label column="true" sm="3">
              Aadhar Number :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="text"
                pattern="[0-9]*" // Allow only digits
                value={aadharNumber}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                  setAadharNumber(value);
                }}
                placeholder="enter aadhar Number "
              />
              {aadharNumberPresent ? (
                <FieldWarning text="Please provide 12 digit Aadhar number" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
        </Col>
        <Row
          sm="4"
          style={{
            alignItems: "center",
            justifyContent: "center",
            margin: "2rem 0rem",
          }}
        >
          <Button
            className={`${userInfo.themeColor}ThemeHeaderTop`}
            type="submit"
            disabled={isLoading}
            variant="primary"
            onClick={handleSubmit}
            style={{ maxWidth: "7rem" }}
          >
            {" "}
            Submit
          </Button>

          <Button
            style={{ marginLeft: "5%", maxWidth: "7rem" }}
            type="button"
            disabled={isLoading}
            variant="secondary"
            onClick={handleCancelButton}
          >
            {" "}
            Cancel
          </Button>
        </Row>
      </Row>
    </>
  );
};

export default AssociateRegisterScreen;
