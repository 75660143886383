import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  FaUser,
  FaRegListAlt,
  FaUsers,
  FaUsersSlash,
  FaMoneyBillAlt,
  FaUserPlus,
  FaClipboardList,
} from "react-icons/fa";
import {
  RiPassPendingFill,
  RiFolderUploadFill,
  RiQrCodeFill,
  RiBuilding4Fill,
  RiLogoutCircleRLine,
} from "react-icons/ri";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import preferredLanguage from "../assets/preferredLanguage.json";

const MobileBottomMenuScreen = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const selectedLanguage = userInfo?.preferredLanguage
    ? userInfo?.preferredLanguage
    : "English";
  return (
    <>
      {userInfo && (
        <div
          className={`${userInfo?.themeColor}ThemeFooter`}
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            padding: "0.8rem 0",
            zIndex: 1000,
          }}
        >
          <Link
            to="/allUsers"
            style={{ ...menuButtonStyle, textDecoration: "none" }}
          >
            {" "}
            <FaUsers
              style={{
                width: "1.5rem",
                height: "1.5rem",
                marginBottom: "0.1rem",
              }}
            />
            {preferredLanguage[selectedLanguage]?.directMenu.members}{" "}
          </Link>
          <Link
            to="/subsExpiredUsers"
            style={{ ...menuButtonStyle, textDecoration: "none" }}
          >
            {" "}
            <FaUsersSlash
              style={{
                width: "1.5rem",
                height: "1.5rem",
                marginBottom: "0.1rem",
              }}
            />
            {preferredLanguage[selectedLanguage]?.directMenu.members}
          </Link>
          <Link
            to="/userRegistration"
            style={{ ...menuButtonStyle, textDecoration: "none" }}
          >
            {" "}
            <FaUserPlus
              style={{
                width: "1.5rem",
                height: "1.5rem",
                marginBottom: "0.1rem",
              }}
            />
            {preferredLanguage[selectedLanguage]?.directMenu.member}
          </Link>
          <Link
            to="/visitors"
            style={{ ...menuButtonStyle, textDecoration: "none" }}
          >
            {" "}
            <FaRegListAlt
              style={{
                width: "1.5rem",
                height: "1.5rem",
                marginBottom: "0.1rem",
              }}
            />
            {preferredLanguage[selectedLanguage]?.directMenu.visitors}
          </Link>{" "}
          {(userInfo.role === "owner" || userInfo.role === "Super Admin") && (
            <Link
              to="/payments"
              style={{ ...menuButtonStyle, textDecoration: "none" }}
            >
              {" "}
              <FaMoneyBillAlt
                style={{
                  width: "1.5rem",
                  height: "1.5rem",
                  marginBottom: "0.1rem",
                }}
              />
              {preferredLanguage[selectedLanguage]?.directMenu.payemnts}
            </Link>
          )}{" "}
          <Link
            to="/regPendingUsers"
            style={{ ...menuButtonStyle, textDecoration: "none" }}
          >
            {" "}
            <RiPassPendingFill
              style={{
                width: "1.5rem",
                height: "1.5rem",
                marginBottom: "0.1rem",
              }}
            />
            {preferredLanguage[selectedLanguage]?.directMenu.pendingReg}
          </Link>{" "}
        </div>
      )}
    </>
  );
};

const menuButtonStyle = {
  border: "none",
  fontSize: "0.7rem",
  color: "white",
  cursor: "pointer",
  outline: "none",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  width: "100%",
};

export default MobileBottomMenuScreen;
