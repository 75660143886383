import { Button, Form, Table, Row, Col, InputGroup } from "react-bootstrap";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { XLg } from "react-bootstrap-icons";
import * as XLSX from "xlsx";

import FormContainer from "../components/FormContainer";
import VerticallyCenteredModal from "../components/SubmitModal";
import Loader from "../components/Loader";
import { useUploadUsersMutation } from "../slices/usersSlice";
import { getCurrentDate } from "../utils/getCurrentDate";
import { isValidEmail } from "../utils/isValidEmail";
import { useGetUsersQuery } from "../slices/usersSlice";

const UsersUploadScreen = () => {
  const [excelFile, setExcelFile] = useState(null);
  const [excelData, setExcelData] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const { userInfo } = useSelector((state) => state.auth);
  const [createUser, { isLoading }] = useUploadUsersMutation();
  let errorResponse = null;

  let {
    data: users,
    isLoading: isUsersLoading,
    refetch: usersRefetch,
    error: usersError,
  } = useGetUsersQuery({
    payLoad: userInfo.gymId._id,
    token: userInfo.token,
  });

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    const dotIndex = selectedFile.name.lastIndexOf(".");
    const fileExtension = selectedFile.name.slice(dotIndex + 1);
    if (
      fileExtension === "xlsx" ||
      fileExtension === "xls" ||
      fileExtension === "csv"
    ) {
      try {
        setExcelFile(selectedFile.name);
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e) => {
          const bufferArray = e.target.result;
          const wb = XLSX.read(bufferArray, { type: "buffer" });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws);
          setExcelData(data);
        };
      } catch (e) {
        toast.error("Please select valid excel file");
      }
    } else toast.error("Only files with extensions .xlsx or .xls are allowed");
  };

  const verifyUsersExist = async () => {
    let duplicateUsers = [];
    let existingUsers = isUsersLoading ? [] : users;

    for (let i = 0; i < excelData?.length; i++) {
      for (let j = 0; j < existingUsers?.length; j++) {
        if (excelData[i].userId === existingUsers[j].userId) {
          duplicateUsers.push(excelData[i].userId);
          break;
        }
      }
    }
    return duplicateUsers;
  };

  const getDateFormat = (originalDate) => {
    const days = Math.floor(originalDate - 25569); // Excel's serial number system starts from 1900
    const ms = days * 86400 * 1000; // Convert days to milliseconds
    const date = new Date(ms);
    const finalDate = date?.toISOString().split("T")[0];
    return finalDate;
  };

  const isDataMissing = async () => {
    excelData?.forEach((d) => {
      if (
        !d.firstName ||
        !d.gender ||
        !d.ptEnabled ||
        !d.mobileNumber ||
        !d.subscriptionAmount ||
        !d.paidAmount ||
        !d.joinedDate ||
        !d.dateOfBirth ||
        !d.renewedDate ||
        !d.lastPaidDate ||
        !d.gymGoal ||
        !d.subscriptionExpiryDate
      ) {
        setModalShow(true);
      }
    });
  };

  const verifyExcelData = async () => {
    let isDataValid = true;
    let errorObject = {};
    let uniqueExcelUsers = [];
    let duplicateUserPresent = false;

    excelData?.forEach((d) => {
      d.userId = d.userId?.toString().toLowerCase();
    });
    excelData?.forEach((d) => {
      if (uniqueExcelUsers.includes(d.userId)) {
        duplicateUserPresent = true;
        errorObject.userId =
          "Excel contains duplicate userIds, Please correct it and upload again";
        isDataValid = false;
      } else {
        uniqueExcelUsers.push(d.userId);
      }
    });
    excelData?.forEach((d) => {
      if (typeof d?.joinedDate === "number") {
        const finDate = getDateFormat(d.joinedDate);
        d.joinedDate = finDate;
      }
      if (typeof d?.dateOfBirth === "number") {
        const finDate = getDateFormat(d.dateOfBirth);
        d.dateOfBirth = finDate;
      }
      if (typeof d?.lastPaidDate === "number") {
        const finDate = getDateFormat(d.lastPaidDate);
        d.lastPaidDate = finDate;
      }
      if (typeof d?.renewedDate === "number") {
        const finDate = getDateFormat(d.renewedDate);
        d.renewedDate = finDate;
      }
      if (typeof d?.subscriptionExpiryDate === "number") {
        const finDate = getDateFormat(d.subscriptionExpiryDate);
        d.subscriptionExpiryDate = finDate;
      }
      d.firstName = !d.firstName ? "No Name" : d.firstName;
      d.gender =
        !d.gender ||
        (d.gender.toString().toLowerCase() !== "male" &&
          d.gender.toString().toLowerCase() !== "female" &&
          d.gender.toString().toLowerCase() !== "other")
          ? "Unknown"
          : d.gender;
      d.ptEnabled = !d.ptEnabled ? false : d.ptEnabled;
      d.mobileNumber =
        !d.mobileNumber || typeof Number(d.mobileNumber) !== "number"
          ? 123
          : Number(d.mobileNumber);
      d.subscriptionAmount = !d.subscriptionAmount ? 0 : d.subscriptionAmount;
      if (d.paidAmount > d.subscriptionAmount) {
        d.subscriptionAmount = d.paidAmount;
      }
      d.paidAmount = !d.paidAmount ? 0 : d.paidAmount;
      d.joinedDate = !d.joinedDate ? "2020-01-01" : d.joinedDate;
      d.dateOfBirth = !d.dateOfBirth ? "2021-01-01" : d.dateOfBirth;
      d.renewedDate = !d.renewedDate ? d.joinedDate : d.renewedDate;
      d.lastPaidDate = !d.lastPaidDate ? d.renewedDate : d.lastPaidDate;
      d.gymGoal = !d.gymGoal ? "Muscle Gain and Strength Building" : d.gymGoal;
      d.isActive = !d.isActive ? true : d.isActive;
      if (!d.subscriptionExpiryDate) {
        const monthsLater = new Date(d?.renewedDate);
        monthsLater?.setMonth(
          monthsLater?.getMonth() + parseInt(d?.subscriptionType?.split(" ")[0])
        );
        monthsLater?.setDate(monthsLater?.getDate() - 1);
        d.subscriptionExpiryDate = monthsLater?.toISOString().slice(0, 10);
      }
    });

    // console.log("test");
    // console.log(excelData);

    if (!duplicateUserPresent)
      excelData?.forEach((d) => {
        // console.log("userId");
        // console.log(typeof d.userId);
        // console.log("firstName");
        // console.log(typeof d.firstName);
        // console.log("ptEnabled");
        // console.log(typeof d.ptEnabled);
        // console.log("gender");
        // console.log(typeof d.gender);
        // console.log("subscriptionAmount");
        // console.log(typeof d.subscriptionAmount);
        // console.log("paidAmount");
        // console.log(typeof d.paidAmount);
        // console.log("mobileNumber");
        // console.log(typeof d.mobileNumber);
        // console.log("aadharNumber");
        // console.log(typeof d.aadharNumber);
        // console.log("joinedDate");
        // console.log(typeof d.joinedDate);
        // console.log("dateOfBirth");
        // console.log(typeof d.dateOfBirth);
        // console.log("lastPaidDate");
        // console.log(typeof d.lastPaidDate);
        // console.log("subscriptionType");
        // console.log(typeof d.subscriptionType);
        // console.log("email");
        // console.log(typeof d.email);
        // console.log("gymGoal");
        // console.log(typeof d.gymGoal);
        // console.log("address");
        // console.log(typeof d.address);
        // console.log("isActive");
        // console.log(typeof d.isActive);
        // console.log("renewedDate");
        // console.log(typeof d.renewedDate);
        // console.log("subscriptionExpiryDate");
        // console.log(typeof d.subscriptionExpiryDate);

        const regexDate = /^\d{4}-\d{2}-\d{2}$/;

        if (
          !d.userId ||
          d.userId?.toString().length < 1 ||
          d.userId?.toString().length > 7
        ) {
          // console.log(d.userId);
          errorObject.userId =
            "userId should be between 1 to 7 digit number or text";
          isDataValid = false;
        }
        if (
          !d.firstName ||
          d.firstName?.length < 1 ||
          d.firstName?.length > 150
        ) {
          // console.log(d.userId);
          // console.log(d.firstName);
          errorObject.firstName =
            "firstName should not be empty and it should not contain more than 200 characters";
          isDataValid = false;
        }

        if (
          !(
            d?.isActive?.toString().toLowerCase() === "true" ||
            d?.isActive?.toString().toLowerCase() === "false"
          )
        ) {
          // console.log(d.userId);
          // console.log(d.ptEnabled);
          errorObject.ptEnabled = "ptEnabled should be either true or false";
          isDataValid = false;
        }

        if (
          d.subscriptionAmount === undefined ||
          d.subscriptionAmount === null ||
          typeof d.subscriptionAmount !== "number" ||
          Number(d.subscriptionAmount) < 0 ||
          Number(d.subscriptionAmount) > 99999
        ) {
          // console.log(d.userId);
          // console.log(d.subscriptionAmount);
          errorObject.subscriptionAmount =
            "subscriptionAmount should be between 0 and 99999 Rs";
          isDataValid = false;
        }

        if (
          d.paidAmount === null ||
          typeof d.paidAmount !== "number" || // Check for null
          d.paidAmount === undefined ||
          Number(d.paidAmount) < 0 ||
          Number(d.paidAmount) > Number(d.subscriptionAmount)
        ) {
          // console.log(d.userId);
          // console.log(d.paidAmount);
          errorObject.paidAmount =
            "paidAmount should be atleast 0 and it should not exceed Subscription amount";

          isDataValid = false;
        }

        if (d.aadharNumber)
          if (
            d.aadharNumber?.toString().length !== 12 ||
            Number(d.aadharNumber) < 0
          ) {
            // console.log(d.userId);
            // console.log(d.aadharNumber);
            errorObject.aadharNumber =
              "aadharNumber be either empty or 12 digit Number";
            isDataValid = false;
          }

        if (
          !d.joinedDate ||
          d.joinedDate > getCurrentDate() ||
          !regexDate.test(d.joinedDate)
        ) {
          // console.log(d.userId);
          // console.log(d.joinedDate);
          errorObject.joinedDate =
            "joinedDate should not be empty and it should not be future date";
          isDataValid = false;
        }

        if (
          !d.dateOfBirth ||
          d.dateOfBirth >= getCurrentDate() ||
          !regexDate.test(d.dateOfBirth)
        ) {
          // console.log(d.userId);
          // console.log(d.dateOfBirth);
          errorObject.dateOfBirth =
            "dateOfBirth should not be empty and it should not be future date";
          isDataValid = false;
        }

        if (
          !d.lastPaidDate ||
          d.lastPaidDate > getCurrentDate() ||
          !regexDate.test(d.lastPaidDate)
        ) {
          // console.log(d.userId);
          // console.log(d.lastPaidDate);
          errorObject.lastPaidDate =
            "lastPaidDate should not be empty and it should not be future date";
          isDataValid = false;
        }
        if (
          !d.subscriptionType ||
          !(
            d.subscriptionType === "1 Month" ||
            d.subscriptionType === "1 Months" ||
            d.subscriptionType === "2 Months" ||
            d.subscriptionType === "3 Months" ||
            d.subscriptionType === "4 Months" ||
            d.subscriptionType === "5 Months" ||
            d.subscriptionType === "6 Months" ||
            d.subscriptionType === "7 Months" ||
            d.subscriptionType === "8 Months" ||
            d.subscriptionType === "9 Months" ||
            d.subscriptionType === "10 Months" ||
            d.subscriptionType === "11 Months" ||
            d.subscriptionType === "12 Months" ||
            d.subscriptionType === "13 Months" ||
            d.subscriptionType === "14 Months" ||
            d.subscriptionType === "15 Months" ||
            d.subscriptionType === "16 Months" ||
            d.subscriptionType === "17 Months" ||
            d.subscriptionType === "18 Months" ||
            d.subscriptionType === "24 Months" ||
            d.subscriptionType === "1 month" ||
            d.subscriptionType === "1 months" ||
            d.subscriptionType === "2 months" ||
            d.subscriptionType === "3 months" ||
            d.subscriptionType === "4 months" ||
            d.subscriptionType === "5 months" ||
            d.subscriptionType === "6 months" ||
            d.subscriptionType === "7 months" ||
            d.subscriptionType === "8 months" ||
            d.subscriptionType === "9 months" ||
            d.subscriptionType === "10 months" ||
            d.subscriptionType === "11 months" ||
            d.subscriptionType === "12 months" ||
            d.subscriptionType === "13 months" ||
            d.subscriptionType === "14 months" ||
            d.subscriptionType === "15 months" ||
            d.subscriptionType === "16 months" ||
            d.subscriptionType === "17 months" ||
            d.subscriptionType === "18 months" ||
            d.subscriptionType === "24 months"
          )
        ) {
          // console.log(d.userId);
          // console.log(d.subscriptionType);
          errorObject.subscriptionType = `subscriptionType should be one of "1 Month",
    "2 Months",
    "3 Months",
    "4 Months",
    "5 Months",
    "6 Months",
    "7 Months",
    "8 Months",
    "9 Months",
    "10 Months",
    "11 Months",
    "12 Months",
    "13 Months",
    "14 Months",
    "15 Months",
    "16 Months",
    "17 Months",
    "18 Months",
    "24 Months"`;
          isDataValid = false;
        }

        if (!(isValidEmail(d.email) || d.email?.length === 0)) {
          // console.log(d.userId);
          // console.log(d.email);
          errorObject.email = "email should be valid or empty";
          isDataValid = false;
        }

        if (
          !(
            d.gymGoal?.length === 0 ||
            d.gymGoal === "Weight Loss and Fat Reduction" ||
            d.gymGoal === "Muscle Gain and Strength Building" ||
            d.gymGoal === "Body Building" ||
            d.gymGoal === "Weight Lifting" ||
            d.gymGoal === "To Improve Stamina" ||
            d.gymGoal === "Stay Fit" ||
            d.gymGoal === "Health and Wellness" ||
            d.gymGoal === "Sports Performance" ||
            d.gymGoal === "Injury Rehabilitation"
          )
        ) {
          // console.log(d.userId);
          // console.log(d.gymGoal);
          errorObject.gymGoal =
            'gymGoal should be empty or one of "Weight Loss and Fat Reduction",  "Muscle Gain and Strength Building", "Body Building","Weight Lifting" , "To Improve Stamina" , "Stay Fit", "Health and Wellness" ,"Sports Performance" , "Injury Rehabilitation"';
          isDataValid = false;
        }

        if (
          !(
            d?.isActive?.toString().toLowerCase() === "true" ||
            d?.isActive?.toString().toLowerCase() === "false"
          )
        ) {
          // console.log(d.userId);
          // console.log(d.isActive);
          errorObject.isActive = "isActive should be one of true or false";
          isDataValid = false;
        }

        if (
          d.renewedDate === undefined ||
          d.renewedDate > getCurrentDate() ||
          !regexDate.test(d.renewedDate)
        ) {
          errorObject.renewedDate =
            "renewedDate should not be empty and should not be future date";
          isDataValid = false;
        }

        const isValid = regexDate.test(d.subscriptionExpiryDate);
        if (
          d.subscriptionExpiryDate === null ||
          d.subscriptionExpiryDate === undefined ||
          !isValid
        ) {
          errorObject.subscriptionExpiryDate =
            "subscriptionExpiryDate should be a valid date";
          isDataValid = false;
        }
      });
    errorResponse = Object.values(errorObject).join(". \n");
    return isDataValid;
  };

  const uploadUsers = async (e) => {
    if (excelFile === null) {
      toast.error("Please select excel file");
    } else {
      const duplicateUsers = await verifyUsersExist();
      if (!(await verifyExcelData())) {
        //  console.log(errorResponse);
        toast.error("Please address below errors : \n" + errorResponse);
      } else if (duplicateUsers?.length > 0) {
        toast.error(
          "Below user IDs are already available, Please remove these users from Excel or provide different IDs for these users \n : " +
            duplicateUsers.join(",")
        );
      } else {
        try {
          await createUser({
            token: userInfo.token,
            payload: excelData,
          }).unwrap();
          toast.success(() => <div>"Users Uploaded Successfully"</div>);
          setExcelFile(null);
          setExcelData(null);
        } catch (e) {
          toast.error(e?.data?.error + " : \n " + e.data?.users);
          setExcelFile(null);
          setExcelData(null);
        }
      }
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (excelFile === null) {
      toast.error("Please select excel file");
    } else {
      const duplicateUsers = await verifyUsersExist();
      if (duplicateUsers?.length > 0) {
        toast.error(
          "Below user IDs are already available, Please remove these users from Excel or provide different IDs for these users \n : " +
            duplicateUsers.join(",")
        );
      } else {
        try {
          await isDataMissing();
        } catch (e) {
          toast.error(e?.data?.error + " : \n " + e.data?.users);
        }
      }
    }
  };

  const clearFile = async () => {
    setExcelFile(null);
    document.getElementById("fileInput").value = "";
  };

  const sampleExcelData = [
    {
      userId: 3080,
      firstName: "Sachin",
      gender: "Male",
      ptEnabled: true,
      subscriptionType: "1 Month",
      subscriptionAmount: 3000,
      paidAmount: 1000,
      joinedDate: "2024-03-17",
      dateOfBirth: "2021-05-17",
      lastPaidDate: "2024-03-17",
      renewedDate: "2024-04-17",
      subscriptionExpiryDate: "2024-05-17",
      mobileNumber: 8787878787,
      aadharNumber: "987876542345",
      email: "test@gmail.com",
      gymGoal: "Weight Loss and Fat Reduction",
      isActive: true,
      address: "flat 7, block d, casagrande, adyar, chennai - 600073",
      notes: "2 months free",
    },
    {
      userId: 1239,
      firstName: "Smriti",
      gender: "Female",
      ptEnabled: false,
      subscriptionType: "3 Months",
      subscriptionAmount: 6000,
      paidAmount: 4000,
      joinedDate: "2024-03-12",
      dateOfBirth: "2021-01-17",
      lastPaidDate: "2024-03-17",
      renewedDate: "2024-05-17",
      subscriptionExpiryDate: "2024-08-17",
      mobileNumber: 9090989897,
      email: "smriti@gmail.com",
      aadharNumber: "",
      gymGoal: "Stay Fit",
      isActive: true,
      address: "",
      notes: "buy 6 months and get 2 months free",
    },
    {
      userId: 5031,
      firstName: "Raina",
      gender: "Male",
      ptEnabled: true,
      subscriptionType: "12 Months",
      subscriptionAmount: 9000,
      paidAmount: 5000,
      joinedDate: "2024-03-17",
      dateOfBirth: "2021-05-17",
      lastPaidDate: "2024-03-17",
      renewedDate: "2024-04-17",
      subscriptionExpiryDate: "2024-05-17",
      mobileNumber: 6876878787,
      aadharNumber: "487876542341",
      email: "raina@gmail.com",
      gymGoal: "Sports Performance",
      isActive: true,
      address: "No 23, hitect city, madhapur, hyderabad - 500081",
      notes: "buy 12 months and get 3 months free",
    },
  ];
  const exportToExcel = () => {
    try {
      const formattedData = sampleExcelData?.map((eq) => eq);
      const workSheet = XLSX.utils.json_to_sheet(formattedData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "UsersList");
      XLSX.writeFile(workBook, "UsersList.xlsx");
    } catch (e) {
      toast.error("error downloading sample file");
    }
  };

  return (
    <>
      {" "}
      <h3>Upload Users</h3>
      <VerticallyCenteredModal
        style={{ color: "red" }}
        title="Some of the data are missing in the excel file, missing data will be replaced by Other data as mentioned below. Please read carefully and confirm the upload, OR Cancel the Upload and verify the data in the excel file"
        size="lg"
        payload={{
          firstName: "if not given, “No Name” will be saved in place",
          gender: "if not given, “Unknown” will be saved in place",
          mobileNumber: "if not given, then 123 will be saved in place",
          ptEnabled: "if not given, False will be saved in place",
          subscriptionAmount:
            "if not given, Rs.0 will be saved in place. If subscriptionAmount < paidAmount, then paidAmount will be saved in subscriptionAmount",
          paidAmount: "if not given, Rs.0 will be saved in place",
          dateOfBirth: "if not given, 2021-01-01 will be saved in place",
          joinedDate: "if not given, 2020-01-01 will be saved in place",
          renewedDate: "if not given, joined date will be saved in place",
          lastPaidDate: "if not given, renewed date will be saved in place",
          subscriptionExpiryDate:
            "if Not given, expiry date will calculated based on renewedDate and subscriptionType",
          gymGoal:
            "if not given, “Muscle Gain and Strength Building” will be saved in place",
        }}
        show={modalShow}
        onHide={() => setModalShow(false)}
        onSubmit={uploadUsers}
      />
      <FormContainer>
        {isLoading ? (
          <Loader />
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group as={Row} className="my-2">
              <Form.Label>
                <strong>Select Excel file to upload</strong>
              </Form.Label>
              <InputGroup>
                {" "}
                <Form.Control
                  type="file"
                  onChange={handleFileChange}
                  id="fileInput"
                />
                {excelFile ? (
                  <button type="button" onClick={clearFile}>
                    <XLg />
                  </button>
                ) : (
                  ""
                )}
              </InputGroup>
            </Form.Group>

            <Row column="true" sm="6">
              <Col sm="4"></Col>
              <Col sm="5">
                <Button
                  className={`${userInfo.themeColor}ThemeHeaderTop`}
                  type="submit"
                  disabled={isLoading}
                  variant="primary"
                >
                  {" "}
                  Upload
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </FormContainer>
      <Row
        style={{
          marginTop: "70px",
          justifyContent: "center",
        }}
      >
        {" "}
        <Col xs={6} md="auto" sm={6} className="d-flex justify-content-center">
          <Button onClick={exportToExcel} variant="success">
            Download Sample File
          </Button>
        </Col>
      </Row>
      <Row
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "70%",
          margin: "auto",
          marginTop: "20px",
          color: "yellow",
        }}
      >
        Please find the allowed values below
        <div>
          <div className="tableContainer">
            <Table
              striped
              bordered
              hover
              responsive="sm"
              className="table-custom"
            >
              <thead className={`${userInfo.themeColor}ThemeThead`}>
                <tr>
                  <th>Field Name</th>
                  <th>Allowed Values</th>
                </tr>
              </thead>
              <tbody className={`${userInfo.themeColor}ThemeTbody`}>
                <tr>
                  <td>userId</td>
                  <td>any Number between 1 and 9999999</td>
                </tr>
                <tr>
                  <td>firstName</td>
                  <td>Any Name</td>
                </tr>
                <tr>
                  <td>ptEnabled</td>
                  <td>
                    TRUE <strong>or</strong> FALSE
                  </td>
                </tr>
                <tr>
                  <td>gender</td>
                  <td>
                    Male <strong>or</strong> Female <strong>or</strong> Other
                  </td>
                </tr>
                <tr>
                  <td>subscriptionAmount</td>
                  <td>Any Amount between 0 and 99999</td>
                </tr>
                <tr>
                  <td>paidAmount</td>
                  <td>Any Amount between 0 and 99999</td>
                </tr>
                <tr>
                  <td>mobileNumber</td>
                  <td>10 digit Number</td>
                </tr>
                <tr>
                  <td>aadharNumber</td>
                  <td>optional</td>
                </tr>
                <tr>
                  <td>joinedDate</td>
                  <td>Date in YYYY-MM-DD format</td>
                </tr>
                <tr>
                  <td>dateOfBirth</td>
                  <td>Date in YYYY-MM-DD format</td>
                </tr>
                <tr>
                  <td>lastPaidDate</td>
                  <td>
                    Date in YYYY-MM-DD format. If not available, Please provide
                    either Joined Date or Renewed Date
                  </td>
                </tr>
                <tr>
                  <td>subscriptionType</td>
                  <td>
                    should be one of "1 Month", "2 Months", "3 Months", "4
                    Months", "5 Months", "6 Months", "7 Months", "8 Months", "9
                    Months", "10 Months", "11 Months", "12 Months", "13 Months",
                    "14 Months", "15 Months", "16 Months", "17 Months", "18
                    Months", "24 Months"
                  </td>
                </tr>
                <tr>
                  <td>email</td>
                  <td>Optional</td>
                </tr>
                <tr>
                  <td>gymGoal</td>
                  <td>
                    Weight Loss and Fat Reduction <strong>or</strong> Muscle
                    Gain and Strength Building <strong>or</strong> Body Building
                    <strong>or</strong> Weight Lifting <strong>or</strong> To
                    Improve Stamina <strong>or</strong> Stay Fit{" "}
                    <strong>or</strong> Health and Wellness <strong>or</strong>{" "}
                    Sports Performance <strong>or</strong> Injury Rehabilitation
                  </td>
                </tr>
                <tr>
                  <td>address</td>
                  <td>Optional</td>
                </tr>
                <tr>
                  <td>isActive</td>
                  <td>
                    TRUE <strong>or</strong> FALSE
                  </td>
                </tr>
                <tr>
                  <td>renewedDate</td>
                  <td>
                    Date in YYYY-MM-DD format, If not available please provide
                    joined date
                  </td>
                </tr>
                <tr>
                  <td>subscriptionExpiryDate</td>
                  <td>Date in YYYY-MM-DD format</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </Row>
    </>
  );
};

export default UsersUploadScreen;
