import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Table } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import { useNavigate, useParams } from "react-router-dom";
import Message from "../components/Message";
import { getCurrentDate } from "../utils/getCurrentDate";

import { setCredentials } from "../slices/authSlice";
import VerticallyCenteredModal from "../components/SubmitModal";
import {
  useGetWsMessageQuery,
  useSendWsMessageMutation,
} from "../slices/wsMessageSlice";

const WsMessageListScreen = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const [startDate, setStartDate] = useState(getCurrentDate());

  const [endDate, setEndDate] = useState(getCurrentDate());

  const [getApiQuery, setGetApiQuery] = useState(
    `?gymId=${userInfo.gymId._id}&createdAt[gte]=${startDate}T00:00:00.125Z&createdAt[lte]=${endDate}T23:59:59.125Z&fields=gymId, userId, mobileNumber, messageContent, typeOfMessage, createdAt,-_id&sort=-createdAt`
  );

  const [modalShow, setModalShow] = useState(false);
  const [reminderType, setReminderType] = useState("");

  const {
    data: wsMessages,
    isLoading,
    error,
    refetch,
  } = useGetWsMessageQuery({
    query: getApiQuery,
    token: userInfo ? userInfo.token : "Bearer a",
  });

  const [sendReminder] = useSendWsMessageMutation();

  const [messagesToList, setMessagesToList] = useState(
    isLoading ? [] : wsMessages ? wsMessages : []
  );

  const [todaysMessages, setTodaysMessages] = useState(
    isLoading ? [] : wsMessages ? wsMessages.length : 0
  );
  const [renewalMessages, setRenewalMessages] = useState("");
  const [inActiveMessages, setInActiveMessages] = useState("");
  const [renMessages, setRenMessages] = useState("");
  const [subsMessages, setSubsMessages] = useState("");
  const [birthdayMessages, setBirthdayRenMessages] = useState("");

  const dispatch = useDispatch();

  const handleGetUsers = async () => {
    if (startDate > getCurrentDate())
      toast.error("Start date can not be greater than today");
    else if (startDate > endDate)
      toast.error("Start date can not be greater than End date");
    else {
      // if (endDate?.split("T")[0] > getCurrentDate())
      //   setEndDate(getCurrentDate() + getCurrentTime());
      setGetApiQuery(
        `?gymId=${userInfo.gymId._id}&createdAt[gte]=${startDate}T00:00:00.125Z&createdAt[lte]=${endDate}T23:59:59.125Z&fields=gymId, userId, mobileNumber, messageContent, typeOfMessage, createdAt,-_id&sort=-createdAt`
      );
      dispatch(
        setCredentials({
          ...userInfo,
          wsMessageStartDate: startDate,
          wsMessageEndDate: endDate,
        })
      );
    }
  };

  const navigate = useNavigate();
  const handleSendPromotional = () => {
    navigate("/promotionalMessage");
  };

  const initiateReminder = async (endPoint) => {
    setReminderType(endPoint);
    setModalShow(true);
  };
  const sendWsReminder = async () => {
    try {
      const resData = await sendReminder({
        token: userInfo.token,
        params: reminderType,
      }).unwrap();
      toast.success(resData.Message);
      setReminderType("");
    } catch (e) {
      toast.error(e?.data?.error || e.data);
    }
  };
  // const sendInActiveReminder = async () => {
  //   try {
  //     const resData = await sendReminder({
  //       token: userInfo.token,
  //       params: "sendInActiveReminder",
  //     }).unwrap();
  //     toast.success(resData.Message);
  //   } catch (e) {
  //     toast.error(e?.data?.error || e.data);
  //   }
  // };

  useEffect(() => {
    const todaysMessages = isLoading ? [] : wsMessages ? wsMessages : [];
    setTodaysMessages(todaysMessages);
    setMessagesToList(todaysMessages);

    const renewalMsg = isLoading
      ? []
      : wsMessages
      ? wsMessages?.filter((msg) => msg?.typeOfMessage === "Renewal Reminder")
      : [];

    const inActiveMsg = isLoading
      ? []
      : wsMessages
      ? wsMessages?.filter((msg) => msg?.typeOfMessage === "InActive Reminder")
      : [];

    const renMsg = isLoading
      ? []
      : wsMessages
      ? wsMessages?.filter((msg) => msg?.typeOfMessage === "Renewal")
      : [];
    const subsMsg = isLoading
      ? []
      : wsMessages
      ? wsMessages?.filter((msg) => msg?.typeOfMessage === "Subscription")
      : [];
    const bdMsg = isLoading
      ? []
      : wsMessages
      ? wsMessages?.filter((msg) => msg?.typeOfMessage === "Birthday")
      : [];
    setBirthdayRenMessages(bdMsg);
    setRenMessages(renMsg);
    setSubsMessages(subsMsg);
    setRenewalMessages(renewalMsg);
    setInActiveMessages(inActiveMsg);
  }, [setTodaysMessages, wsMessages, isLoading]);

  const handleFilterSelecttion = (val) => {
    setMessagesToList(val);
  };
  const handleSubmit = async () => {
    await sendWsReminder();
  };

  return (
    <>
      <label>
        <strong>
          WhatsApp Notification List{" "}
          {/* <button
            style={{ color: "red" }}
            onClick={(e) => initiateReminder("sendRenewalReminder")}
          >
            Send Renewal Reminder
          </button>
          <button
            style={{ color: "green" }}
            onClick={(e) => initiateReminder("sendInActiveReminder")}
          >
            Send InActive Reminder
          </button> */}
        </strong>
      </label>{" "}
      <VerticallyCenteredModal
        title="Want to Send whatsApp message ?"
        show={modalShow}
        size="sm"
        onHide={() => setModalShow(false)}
        onSubmit={handleSubmit}
      />
      <Row
        className="mb-1"
        style={{
          justifyContent: "center",
          margin: "auto",
          width: "95%",
          alignItems: "center",
        }}
      >
        <Col xs={6} md="auto" sm={6}>
          <Form.Group controlId="visitorMobile">
            <Form.Control
              type="date"
              placeholder="2023-11-23"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            ></Form.Control>{" "}
          </Form.Group>
        </Col>
        <Col xs={6} md="auto" sm={6}>
          <Form.Group controlId="visitorMobile">
            <Form.Control
              type="date"
              placeholder="2023-11-27"
              value={endDate?.split("T")[0]}
              onChange={(e) => setEndDate(e.target.value)}
            ></Form.Control>{" "}
          </Form.Group>
        </Col>
        <Col xs={12} md="auto" sm={6} className="d-flex justify-content-center">
          <Form.Group className="my-1" controlId="gymType">
            <Button
              className={`${userInfo.themeColor}ThemeHeaderTop`}
              onClick={handleGetUsers}
            >
              Get Messages
            </Button>
          </Form.Group>
        </Col>
        <Col xs={12} md="auto" sm={6} className="d-flex justify-content-center">
          <Form.Group className="my-1" controlId="gymType">
            <Button className="buttonReddish" onClick={handleSendPromotional}>
              Send Promotions
            </Button>
          </Form.Group>
        </Col>
      </Row>
      {userInfo.role === "owner" ||
      userInfo.role === "Super Admin" ||
      userInfo.role === "admin" ? (
        <>
          <Row
            className="mb-2"
            style={{
              margin: "auto",
              width: "95%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              className="marginPointOne sixteenWidth buttonGreen numbersButtonDashboard"
              onClick={() => handleFilterSelecttion(todaysMessages)}
            >
              Total<strong> {todaysMessages?.length}</strong>
            </Button>
            <Button
              className="marginPointOne sixteenWidth buttonTeal numbersButtonDashboard"
              onClick={() => handleFilterSelecttion(renewalMessages)}
            >
              Validity<strong> {renewalMessages?.length}</strong>
            </Button>
            <Button
              className="marginPointOne sixteenWidth buttonOrchid numbersButtonDashboard"
              onClick={() => handleFilterSelecttion(inActiveMessages)}
            >
              InActive<strong> {inActiveMessages?.length}</strong>
            </Button>
            <Button
              className="marginPointOne sixteenWidth buttonGreenish numbersButtonDashboard"
              onClick={() => handleFilterSelecttion(subsMessages)}
            >
              Subscription<strong> {subsMessages?.length}</strong>
            </Button>
            <Button
              className="marginPointOne sixteenWidth buttonOrange numbersButtonDashboard"
              onClick={() => handleFilterSelecttion(renMessages)}
            >
              Renewal<strong> {renMessages?.length}</strong>
            </Button>
            <Button
              className="marginPointOne sixteenWidth buttonTeal numbersButtonDashboard"
              onClick={() => handleFilterSelecttion(birthdayMessages)}
            >
              Birthday<strong> {birthdayMessages?.length}</strong>
            </Button>
          </Row>
        </>
      ) : (
        ""
      )}
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message>{error?.data?.error}</Message>
      ) : messagesToList ? (
        <div style={{ height: "100vh" }}>
          <div className="tableContainer">
            <Table
              striped
              bordered
              hover
              responsive="sm"
              className="table-custom"
            >
              <thead className={`${userInfo.themeColor}ThemeThead`}>
                <tr>
                  <th style={{ whiteSpace: "nowrap" }}>Member Id</th>
                  <th>Mobile</th>
                  <th style={{ whiteSpace: "nowrap" }}>Message Type</th>
                  <th>Date</th>
                  <th>Message</th>
                </tr>
              </thead>
              <tbody className={`${userInfo.themeColor}ThemeTbody`}>
                {messagesToList?.map((msg) => (
                  <tr>
                    <td>{msg?.userId}</td>
                    <td
                      style={{
                        color:
                          msg?.mobileNumber?.toString().length !== 10
                            ? "red"
                            : "",
                      }}
                    >
                      {msg?.mobileNumber}
                    </td>
                    <td>{msg?.typeOfMessage}</td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {" "}
                      {msg?.createdAt?.split("T")[0]}
                    </td>
                    <td>
                      {userInfo?.isMobileScreen ? "..." : msg?.messageContent}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default WsMessageListScreen;
