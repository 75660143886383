import { Button, Col, Form, Row, Image, Card } from "react-bootstrap";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { useUpdateMyGymMutation } from "../slices/gymSlice";
import ProfileImage from "../components/ProfileImage";
import { useUploadPhotoMutation } from "../slices/usersSlice";
import preferredLanguage from "../assets/preferredLanguage.json";

const EditAttendancePageScreen = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const [offerOne, setOfferOne] = useState("");

  const [imageError, setImageError] = useState(false);
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [fileInvalidError, setFileInvalidError] = useState(false);

  const [selectedLanguage, setSelectedLanguage] = useState(
    userInfo.preferredLanguage ? userInfo.preferredLanguage : "English"
  );

  const [updateGymDetails, { isLoading }] = useUpdateMyGymMutation();
  const [uploadPhoto, { isLoading: isPhotoUploading }] =
    useUploadPhotoMutation();

  let gymId;
  if (userInfo) {
    gymId = userInfo?.gymId?._id;
  }

  const submitHandler = async (e) => {
    // e.preventDefault();
    try {
      const data = {
        payload: {
          offerOne,
        },
        token: userInfo?.token,
      };
      await updateGymDetails(data).unwrap();
      toast.success(
        "Updated successfuly, Please refresh Attendance Page and check"
      );
    } catch (e) {
      toast.error(e?.data?.error || e.data);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      // Reset errors
      setFileSizeError(false);
      setFileInvalidError(false);

      // Check file size and type
      if (selectedFile.size > 10 * 1024 * 1024) {
        setFileSizeError(true);
        return;
      }
      if (
        ![
          "image/jpeg",
          "image/jpg",
          "image/png",
          "image/tiff",
          "image/svg+xml",
        ].includes(selectedFile.type)
      ) {
        setFileInvalidError(true);
        return;
      }

      // Set file and create preview URL
      setFile(selectedFile);
      setFilePreview(URL.createObjectURL(selectedFile));
    }
  };

  const handleSubmit = async () => {
    try {
      if (file) {
        let formData = new FormData();
        formData.append("image", file);
        formData.append("imageKey", `${userInfo?.gymId?._id}`);
        const imageUploadRes = await uploadPhoto({
          token: userInfo.token,
          payload: formData,
        }).unwrap();

        if (imageUploadRes) {
          toast.success(() => (
            <div>
              Uploaded Successfully, Please refresh Attendance page and Check
              <br />
            </div>
          ));
        }
        setFile(null);
      }
    } catch (e) {
      console.log(e);
      toast.error("Photo upload failed");
    }
  };

  return (
    <>
      <Row
        style={{
          alignItems: "center",
          height: "80vh",
          marginTop: "5px",
        }}
      >
        <Col md={6}>
          <Card style={{ borderStyle: "none", height: "60vh" }}>
            <h6 style={{ color: "black", textAlign: "center", margin: "10px" }}>
              Upload Gym Photo{" "}
            </h6>
            {/* Conditionally display the image preview */}
            {filePreview && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <img
                  src={filePreview}
                  alt="Preview"
                  style={{ width: "60%", borderRadius: "8px" }}
                />
              </div>
            )}
            <Form.Group
              as={Row}
              className="my-2"
              controlId="photo"
              style={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Col sm="6">
                <Form.Control type="file" onChange={handleFileChange} />
                {fileSizeError && (
                  <label style={{ color: "red" }}>
                    Image size should be less than 10 MB
                  </label>
                )}
                {fileInvalidError && (
                  <label style={{ color: "red" }}>
                    Only jpeg, jpg, png, tiff, and svg formats are supported
                  </label>
                )}
              </Col>
            </Form.Group>
            {file && (
              <Row column="true" sm="6">
                <Col sm="4"></Col>
                <Col sm="5">
                  <Button
                    className={`${userInfo.themeColor}ThemeHeaderTop`}
                    type="submit"
                    variant="primary"
                    onClick={handleSubmit}
                  >
                    Submit Photo
                  </Button>
                </Col>
              </Row>
            )}
          </Card>
        </Col>

        <Col md={6}>
          <Col sm="12">
            <Form.Control
              as="textarea"
              rows={8}
              value={offerOne}
              onChange={(e) => setOfferOne(e.target.value)}
              placeholder="type display message 1"
              style={{
                fontSize: "50px",
                backgroundColor: "transparent",
                color: "white",
                alignContent: "center",
                marginTop: "10px",
                whiteSpace: "pre-wrap",
              }}
            ></Form.Control>
            <Button
              className="marginPointEight sixteenWidth buttonPeach numbersButtonDashboard"
              onClick={submitHandler}
              style={{ marginTop: "10px", marginLeft: "150px" }}
            >
              Submit Display Msg
            </Button>
          </Col>
        </Col>
      </Row>
    </>
  );
};

export default EditAttendancePageScreen;
