import { Button, Form, Row, Badge } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import FormContainer from "../components/FormContainer";
import Loader from "../components/Loader";
import {
  useGetAttendanceQuery,
  useUpdateAttendanceMutation,
} from "../slices/attendanceSlice";
import welcomeMessage from "../assets/audios/messageTone.mp3";
import errorTone from "../assets/audios/error_tone.mp3";
import birthdayTone from "../assets/audios/happyBirthday.mp3";
import { setCredentials } from "../slices/authSlice";
import ProfileImage from "../components/ProfileImage";
import { getCurrentDate } from "../utils/getCurrentDate";
import { useGetVisitorsQuery } from "../slices/visitorsSlice";
import { useGetPaymentsQuery } from "../slices/paymentSlice";
import { useGetRenewalsQuery } from "../slices/renewalSlice";
import { useGetUserQuery, useGetUsersQuery } from "../slices/usersSlice";
import preferredLanguage from "../assets/preferredLanguage.json";

const LaunchScreen = () => {
  const [userId, setUserId] = useState();
  const { userInfo } = useSelector((state) => state.auth);
  const [attendanceToday, setAttendanceToday] = useState(0);
  const [visitorsToday, setVisitorsToday] = useState(0);
  const [subscriptionsToday, setSubscriptionsToday] = useState(0);
  const [renewalsToday, setRenewalsToday] = useState(0);
  const [paymentsToday, setPaymentsToday] = useState(0);
  const [trendsVisible, setTrendsVisible] = useState(
    userInfo?.trendsVisible ? userInfo.userInfo : true
  );
  const [refreshTrend, setRefreshTrend] = useState(false);
  const [currentUsersAtGym, setCurrentUsersAtGym] = useState(0);

  const [updateAttendance, { isLoading }] = useUpdateAttendanceMutation();

  const [isToggled, setIsToggled] = useState(userInfo.audioOn);
  const dispatch = useDispatch();
  const handleToggle = () => {
    setIsToggled(!isToggled);
    dispatch(setCredentials({ ...userInfo, audioOn: !isToggled }));
  };

  // const [toastMessage, setToastMessage] = useState(false);

  // const handleToastMessage = () => {
  //   setToastMessage(!toastMessage);
  // };
  const selectedLanguage = userInfo?.preferredLanguage
    ? userInfo?.preferredLanguage
    : "English";
  const offset = 5 * 60 * 60 * 1000 + 30 * 60 * 1000;
  let startDate =
    new Date(`${getCurrentDate()}T00:00:00.567Z`).getTime() - offset;

  let endDate =
    new Date(`${getCurrentDate()}T23:59:59.567Z`).getTime() - offset;
  const {
    data: attendances,
    isLoading: isAttendanceLoading,
    error,
    refetch,
  } = useGetAttendanceQuery({
    query: `?gymId=${userInfo.gymId._id}&timeIn[gte]=${startDate}&fields=userId,timeIn,timeOut`,
    token: userInfo ? userInfo.token : "Bearer a",
  });

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!userInfo) toast.error("Please login to use the application");
    if (
      userId?.toString().length < 1 ||
      userId?.toString().length > 999999 ||
      !userId
    )
      toast.error("Please enter valid Member Id");
    else {
      try {
        const res = await updateAttendance({
          payLoad: {
            userId: userId.toString().toLowerCase(),
          },
          token: userInfo.token,
        }).unwrap();
        // dispatch(setCredentials({ ...res }));
        // navigate(redirect);
        // navigate("/");
        if (res.subsAlreadyExpired) {
          toast.error(
            () => (
              <div
                style={{
                  padding: "20px",
                  fontSize: "1.2em",
                  lineHeight: "1.5",
                  backgroundColor: "red",
                }}
              >
                <audio
                  src={errorTone}
                  type="audio/mp3"
                  autoPlay={isToggled}
                ></audio>
                <ProfileImage
                  style={{ width: "500px", height: "auto", margin: "10px 0" }}
                  imageId={userInfo?.gymId._id + "" + res?.userId}
                />{" "}
                <br></br>
                <label style={{ color: "white" }}>
                  Subscription EXPIRED on : {res?.expiresOn?.split("T")[0]}
                </label>
                {res?.pendingAmount > 0 && (
                  <label style={{ color: "red" }}>
                    Pending Amount : {res?.pendingAmount}
                  </label>
                )}
                <br></br>
              </div>
            ),
            {
              style: { width: userInfo?.isMobileScreen ? "350px" : "500px" },
            }
          );
          setRefreshTrend(!refreshTrend);
        } else {
          {
            res?.dateOfBirth?.toString().split("T")[0] !== "2021-01-01" &&
            res?.dateOfBirth?.toString().split("T")[0].substring(4) ===
              getCurrentDate().substring(4)
              ? toast.success(
                  () => (
                    <div
                      style={{
                        padding: "20px",
                        fontSize: "1.2em",
                        lineHeight: "1.5",
                        textAlign: "center", // Center align the content
                        position: "relative", // Required for overlay graphics
                        background: "linear-gradient(135deg, #00d2ff, #3a7bd5)", // Gradient background
                        borderRadius: "15px",
                        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                        overflow: "hidden",
                      }}
                    >
                      {/* Play Birthday Tone */}
                      <audio
                        src={birthdayTone}
                        type="audio/mp3"
                        autoPlay={isToggled}
                      ></audio>
                      {/* Display Profile Image */}
                      <ProfileImage
                        style={{
                          margin: "10px 0",
                          borderRadius: "50%",
                          border: "5px solid white",
                          boxShadow: "0 0 15px rgba(0, 0, 0, 0.2)",
                        }}
                        imageId={userInfo.gymId._id + "" + res?.userId}
                      />
                      {/* Birthday Message */}
                      <br />
                      <strong style={{ fontSize: "1.5em", color: "#fff" }}>
                        🎉 {res?.message} {res?.userName}! 🎂
                      </strong>
                      <br />
                      <strong style={{ fontSize: "1.5em", color: "green" }}>
                        Happy Birthday
                      </strong>
                      <br />
                      <strong>
                        <p style={{ color: "green" }}>
                          {res?.message?.includes("Bye") &&
                            "Workout Minutes : " +
                              (
                                (Number(res?.timeOut) - Number(res?.timeIn)) /
                                60000
                              ).toFixed(0)}
                        </p>
                      </strong>
                      <p style={{ color: "#fff", fontSize: "1em" }}>
                        Valid Till: {res?.expiresOn?.split("T")[0]}
                        <br />
                        Expires in:{" "}
                        {Math.ceil(
                          (new Date(res?.expiresOn) - new Date()) /
                            (1000 * 60 * 60 * 24)
                        ) === 0
                          ? "Expiring Today"
                          : Math.ceil(
                              (new Date(res?.expiresOn) - new Date()) /
                                (1000 * 60 * 60 * 24)
                            ) + " days"}
                      </p>{" "}
                      {res?.pendingAmount > 0 && (
                        <label style={{ color: "red" }}>
                          Pending Amount : {res?.pendingAmount}
                        </label>
                      )}
                    </div>
                  ),
                  {
                    style: {
                      width: userInfo?.isMobileScreen ? "350px" : "500px",
                    },
                  }
                )
              : toast.success(
                  () => (
                    <div
                      style={{
                        padding: "20px",
                        fontSize: "1.2em",
                        lineHeight: "1.5",
                        textAlign: "center", // Center align the content
                        position: "relative", // Required for overlay graphics
                        // background: "linear-gradient(135deg, #ff9a9e, #fad0c4)", // Gradient background
                        borderRadius: "15px",
                        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                        overflow: "hidden",
                      }}
                    >
                      {/* Play Birthday Tone */}
                      <audio
                        src={welcomeMessage}
                        type="audio/mp3"
                        autoPlay={isToggled}
                      ></audio>
                      {/* Display Profile Image */}
                      <ProfileImage
                        style={{
                          margin: "10px 0",
                          borderRadius: "50%",
                        }}
                        imageId={userInfo.gymId._id + "" + res?.userId}
                      />
                      <br />
                      <strong style={{ fontSize: "1.5em" }}>
                        {res?.message} {res?.userName}
                      </strong>
                      <strong>
                        <p style={{ color: "green" }}>
                          {res?.message?.includes("Bye") &&
                            "Workout Minutes : " +
                              (
                                (Number(res?.timeOut) - Number(res?.timeIn)) /
                                60000
                              ).toFixed(0)}
                        </p>
                      </strong>
                      <p style={{ fontSize: "1em" }}>
                        Valid Till : {res?.expiresOn?.split("T")[0]}
                        <br />
                        Expires in{" "}
                        {Math.ceil(
                          (new Date(res?.expiresOn) - new Date()) /
                            (1000 * 60 * 60 * 24)
                        ) === 0
                          ? "Expiring Today"
                          : Math.ceil(
                              (new Date(res?.expiresOn) - new Date()) /
                                (1000 * 60 * 60 * 24)
                            ) + " days"}
                      </p>{" "}
                      {res?.pendingAmount > 0 && (
                        <label style={{ color: "red" }}>
                          Pending Amount : {res?.pendingAmount}
                        </label>
                      )}
                    </div>
                  ),
                  {
                    style: {
                      width: userInfo?.isMobileScreen ? "350px" : "500px",
                    },
                  }
                );
          }
        }
        setUserId("");
      } catch (err) {
        err.status === "PARSING_ERROR" || err.status === 400
          ? toast.error(() => (
              <div>
                <audio
                  src={errorTone}
                  type="audio/mp3"
                  autoPlay={isToggled}
                ></audio>
                <br />
                Please enter valid Member ID
              </div>
            ))
          : toast.error(err.error);
      }
    }
  };

  return (
    <>
      <div
        style={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FormContainer>
          <Form onSubmit={submitHandler}>
            <Form.Group className="my-5" controlId="userId">
              <Form.Label
                id="userId"
                style={{
                  marginTop: "9rem",
                }}
              >
                {
                  preferredLanguage[selectedLanguage]?.attendancePage
                    .typeMemberId
                }
              </Form.Label>
              <Form.Control
                style={{ height: "50px" }}
                type="text"
                placeholder="Member Id"
                autoComplete="off"
                autoFocus
                value={userId || ""}
                onChange={(e) => {
                  // Update userId only if input is alphanumeric (no special characters)
                  const newValue = e.target.value.replace(/[^a-zA-Z0-9]/g, ""); // Allow only letters and numbers
                  setUserId(newValue);
                }}
              />
            </Form.Group>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                className={`${userInfo.themeColor}ThemeHeaderTop`}
                type="submit"
                variant="primary"
              >
                {preferredLanguage[selectedLanguage]?.attendancePage.submit}
              </Button>
            </div>
            {isLoading && <Loader />}
          </Form>
        </FormContainer>
      </div>
      <br />
      <br />

      <div
        style={{
          display: "flex", // Enable flexbox
          flexDirection: "row", // Stack content vertically
          alignItems: "center", // Center items vertically
          justifyContent: "center", // Center items horizontally
          width: "100%",
        }}
      >
        <lebel style={{ color: "white" }}>
          <strong>
            {" "}
            {preferredLanguage[selectedLanguage]?.attendancePage.audio}{" "}
            :&nbsp;&nbsp;&nbsp;
          </strong>
        </lebel>
        <>
          <button style={{ borderRadius: "8px" }} onClick={handleToggle}>
            {isToggled ? "ON" : "OFF"}
          </button>
        </>
      </div>
    </>
  );
};

export default LaunchScreen;
