import { Row, Col } from "react-bootstrap";

const DownloadAppScreen = () => {
  return (
    <div style={{ textAlign: "center", justifyContent: "center" }}>
      <label>click below link to download Android app</label>
      <br />
      <Row className="py-2">
        <Col>
          <br />
          <a
            style={{ color: "yellow" }}
            href="https://zpottergym.s3.ap-south-1.amazonaws.com/zpotter_App/zpotter-android-app.apk"
            download
          >
            Download Android App
          </a>
          <br />
          <br />
          <br />
          <label>Released on : 11-Dec-2024</label>
        </Col>
      </Row>
    </div>
  );
};

export default DownloadAppScreen;
