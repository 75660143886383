import { React, useEffect, useState } from "react";
import { Row, Col, Form, Button, Table, Badge } from "react-bootstrap";
import { Pencil, CheckLg, XLg } from "react-bootstrap-icons";
import { useSelector, useDispatch } from "react-redux";
import * as XLSX from "xlsx"; // Importing xlsx library
import jsPDF from "jspdf"; // Importing jsPDF library
import "jspdf-autotable"; // Importing jsPDF Autotable plugin
import { FaSort } from "react-icons/fa";

import Loader from "../components/Loader";
import Message from "../components/Message";
import VerticallyCenteredModal from "../components/SubmitModal";
import FieldWarning from "../components/FieldWarning";
import { setCredentials } from "../slices/authSlice";

import { toast } from "react-toastify";
import { getCurrentDate, getCurrentTime } from "../utils/getCurrentDate";
import {
  useCreateEquipmentMutation,
  useGetEquipmentsQuery,
  useUpdateEquipmentMutation,
} from "../slices/equipmentSlice";

const EquipmentsScreen = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const [editEnabled, setEditEnabled] = useState("");
  const [equipmentRate, setEquipmentRate] = useState();
  const [equipmentName, setEquipmentName] = useState("");
  const [equipmentSize, setEquipmentSize] = useState();
  const [equipmentCount, setEquipmentCount] = useState(1);
  const [equipmentMaterial, setEquipmentMaterial] = useState();
  const [purchasedDate, setPurchasedDate] = useState(getCurrentDate());
  const [notes, setNotes] = useState("");
  const [showAdd, setShowAdd] = useState(false);

  const [equipmentRatePresent, setEquipmentRatePresent] = useState();
  const [equipmentNamePresent, setEquipmentNamePresent] = useState();
  const [equipmentSizePresent, setEquipmentSizePresent] = useState();
  const [equipmentCountPresent, setEquipmentCountPresent] = useState();
  const [equipmentMaterialPresent, setEquipmentMaterialPresent] = useState();
  const [purchasedDatePresent, setPurchasedDatePresent] = useState(
    getCurrentDate()
  );
  const [notesPresent, setNotesPresent] = useState("");

  const [addModalShow, setAddModalShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [filterValue, setFilterValue] = useState("");

  const [startDate, setStartDate] = useState(getCurrentDate());
  const [endDate, setEndDate] = useState(getCurrentDate());

  const [getApiQuery, setGetApiQuery] = useState(
    `updatedAt[gte]=${startDate}T00:00:00.000Z&updatedAt[lte]=${endDate}T23:59:59.000Z${filterValue}&sort=-equipmentName`
  );

  const [newEquipmentAdded, setNewEquipmentAdded] = useState("");
  const [equipmentsToList, setEquipmentsToList] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "asc",
  });

  const dispatch = useDispatch();

  const equipmentNames = [
    "Select Equipment",
    "Treadmill",
    "Elliptical Trainer",
    "Stationary Bike",
    "Rowing Machine",
    "Leg Press Machine",
    "Smith Machine",
    "Lat Pulldown Machine",
    "Chest Press Machine",
    "Cable Crossover Machine",
    "Leg Curl Machine",
    "Leg Extension Machine",
    "Dumbbells",
    "Barbells",
    "Kettlebells",
    "Weight Plates",
    "Bench Press",
    "Squat Rack",
    "Pull-up Bar",
    "Abdominal Crunch Machine",
    "Seated Row Machine",
    "Hack Squat Machine",
    "Preacher Curl Bench",
    "Incline Bench Press",
    "Flat Bench",
    "Decline Bench",
    "Power Rack",
    "Leg Abduction Machine",
    "Leg Adduction Machine",
    "Dip Bar",
    "Pec Deck Machine",
    "Triceps Extension Machine",
    "Hyperextension Bench",
    "Battle Ropes",
    "Sled Push",
    "Sandbags",
    "Medicine Balls",
    "Resistance Bands",
    "Foam Rollers",
    "Suspension Trainer (TRX)",
    "Glute Ham Developer (GHD)",
    "Roman Chair",
    "Climbing Rope",
    "Plyo Box",
    "Weighted Vest",
    "Ankle Weights",
    "Farmer's Walk Handles",
    "Calf Raise Machine",
    "Ab Wheel",
    "Stair Climber",
    "Air Bike",
    "Others",
  ];
  const filterOptions = [
    "&equipmentName=All",
    "&equipmentName=Treadmill",
    "&equipmentName=Elliptical Trainer",
    "&equipmentName=Stationary Bike",
    "&equipmentName=Rowing Machine",
    "&equipmentName=Leg Press Machine",
    "&equipmentName=Others",
  ];

  let {
    data: equipments,
    isLoading,
    error,
    refetch,
  } = useGetEquipmentsQuery({
    token: userInfo ? userInfo.token : "Bearer a",
    query: getApiQuery,
  });

  const [updateEquipment] = useUpdateEquipmentMutation();
  const [createEquipment] = useCreateEquipmentMutation();

  useEffect(() => {
    if (purchasedDate <= getCurrentDate()) {
      setPurchasedDatePresent(false);
    }

    if (equipmentName && equipmentName !== "Select Equipment") {
      setEquipmentNamePresent(false);
    }

    if (equipmentRate) {
      setEquipmentRatePresent(false);
    }

    if (equipmentCount) {
      setEquipmentCountPresent(false);
    }

    if (equipmentMaterial?.length <= 100) {
      setEquipmentMaterialPresent(false);
    }

    if (equipmentSize?.length <= 50) {
      setEquipmentSizePresent(false);
    }

    if (notes?.length > 1000) {
      setNotesPresent(false);
    }
  }, [
    purchasedDate,
    equipmentCount,
    equipmentMaterial,
    equipmentName,
    equipmentRate,
    equipmentSize,
    notes,
  ]);

  const verifyInputFeilds = async () => {
    let fieldValidationSuccess = true;
    if (
      purchasedDate === undefined ||
      purchasedDate?.split("T")[0] > getCurrentDate()
    ) {
      setPurchasedDatePresent(true);
      fieldValidationSuccess = false;
    }

    if (equipmentName.length === 0 || equipmentName === "Select Equipment") {
      setEquipmentNamePresent(true);
      fieldValidationSuccess = false;
    }

    if (!equipmentRate || equipmentRate === undefined) {
      setEquipmentRate(0);
    }

    if (!equipmentCount || equipmentCount === undefined) {
      setEquipmentCount(1);
    }

    if (equipmentMaterial?.length > 100) {
      setEquipmentMaterialPresent(true);
      fieldValidationSuccess = false;
    }

    if (equipmentSize?.length > 50) {
      setEquipmentSizePresent(true);
      fieldValidationSuccess = false;
    }

    if (notes?.length > 1000) {
      setNotesPresent(true);
      fieldValidationSuccess = false;
    }

    if (!fieldValidationSuccess)
      toast.error(
        "Some of the fields have invalid inputs. Please provide valid details"
      );
    return fieldValidationSuccess;
  };

  const handleAddEquipment = async (user) => {
    if (await verifyInputFeilds())
      try {
        const resData = await createEquipment({
          token: userInfo.token,
          payload: {
            equipmentName: equipmentName,
            equipmentSize: equipmentSize,
            equipmentCount: equipmentCount,
            equipmentRate: equipmentRate,
            equipmentMaterial: equipmentMaterial,
            purchasedDate: purchasedDate,
            notes: notes,
          },
        }).unwrap();
        setNewEquipmentAdded(resData);
        setPurchasedDate(getCurrentDate());
        setEquipmentRate("");
        setEditEnabled();
        setEquipmentName("Select Equipment");
        setEquipmentCount("");
        setEquipmentMaterial("");
        setEquipmentSize("");
        setNotes("");
        toast.success("Equipment added");
      } catch (e) {
        toast.error(e?.data?.error || e.data);
      }
  };

  const submitEdit = async () => {
    if (await verifyInputFeilds())
      try {
        await updateEquipment({
          _id: editEnabled,
          token: userInfo.token,
          payload: {
            equipmentName: equipmentName,
            equipmentSize: equipmentSize,
            equipmentCount: equipmentCount,
            equipmentRate: equipmentRate,
            equipmentMaterial: equipmentMaterial,
            purchasedDate: purchasedDate,
            notes: notes,
          },
        }).unwrap();
        setPurchasedDate(getCurrentDate());
        setEquipmentRate("");
        setEditEnabled();
        setEquipmentName("Select Equipment");
        setEquipmentCount("");
        setEquipmentMaterial("");
        setEquipmentSize("");
        setNotes("");
        toast.success("Equipment Updated");
      } catch (e) {
        toast.error(e?.data?.error || e.data);
      }
  };

  const hanbdleSubmit = async (user) => {
    if (await verifyInputFeilds()) setModalShow(true);
  };

  const hanbdleAddExpense = async (user) => {
    if (await verifyInputFeilds()) setAddModalShow(true);
  };

  const handleGetExpense = async () => {
    if (startDate > getCurrentDate())
      toast.error("Start date can not be greater than today");
    else if (startDate > endDate)
      toast.error("Start date can not be greater than End date");
    else {
      if (endDate?.split("T")[0] > getCurrentDate())
        setEndDate(getCurrentDate() + getCurrentTime());
      // setEndDate(getCurrentDate() + getCurrentTime());
      setGetApiQuery(
        `updatedAt[gte]=${startDate}T00:00:00.000Z&updatedAt[lte]=${endDate}T23:59:59.000Z${filterValue}&sort=-updatedAt`
      );

      dispatch(
        setCredentials({
          ...userInfo,
          equipmentsStartDate: startDate,
          equipmentsEndDate: endDate,
        })
      );
    }
  };

  useEffect(() => {
    refetch();
  }, [refetch, editEnabled, newEquipmentAdded]);

  useEffect(() => {
    const eqs = isLoading ? [] : error ? [] : equipments ? equipments : [];
    const sortedEquipments = [...eqs]?.sort((a, b) => {
      if (
        a[sortConfig.key]?.toString().toLowerCase() <
        b[sortConfig.key]?.toString().toLowerCase()
      ) {
        return sortConfig?.direction === "asc" ? -1 : 1;
      }
      if (
        a[sortConfig.key]?.toString().toLowerCase() >
        b[sortConfig.key]?.toString().toLowerCase()
      ) {
        return sortConfig?.direction === "asc" ? 1 : -1;
      }
      return 0;
    });

    setEquipmentsToList(sortedEquipments);
  }, [equipments, isLoading, error, sortConfig]);

  const handleSelect = (e) => {
    setEquipmentName(e.target.value);
  };

  const handleStatusFilter = (val) => {
    if (val.includes("All")) setFilterValue("");
    else setFilterValue(val);
  };

  const hanbdleCancelEdit = () => {
    setEditEnabled(false);

    setPurchasedDate(getCurrentDate());
    setEquipmentRate("");

    setEquipmentName("Select Equipment");
    setEquipmentCount("");
    setEquipmentMaterial("");
    setEquipmentSize("");
    setNotes("");
  };

  const exportToExcel = () => {
    if (error) {
      toast.error(
        "No Equipments available to Download for the selected Date range"
      );
    } else {
      const formattedData = equipments?.map((eq) => ({
        "Equipment Name": eq.equipmentName,
        "Equipment Size": eq.equipmentSize,
        "Equipment Count": eq.equipmentCount,
        "Equipment Rate": eq.equipmentRate,
        "Equipment Material": eq.equipmentMaterial,
        "Purchased Date": eq.purchasedDate.split("T")[0],
        "Gym Id": eq.gymId,
        "Added By": eq.addedBy,
        "Added On": eq.createdAt.split("T")[0],
        "Updated By": eq.updatedBy,
        notes: eq.notes,
      }));

      const workSheet = XLSX.utils.json_to_sheet(formattedData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Equipments");
      XLSX.writeFile(
        workBook,
        "Equipments_from " + startDate + " to " + endDate + ".xlsx"
      );
    }
  };

  const handleSort = (columnKey) => {
    let direction = "asc";
    if (sortConfig.key === columnKey && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key: columnKey, direction });
  };

  return (
    <>
      <label>
        <strong>Equipments List : &nbsp;</strong>
      </label>
      <Button
        className={`${userInfo.themeColor}ThemeHeaderTop`}
        onClick={(e) => setShowAdd(!showAdd)}
        variant="primary"
        disabled={
          !(userInfo.role === "owner" || userInfo.role === "Super Admin")
        }
      >
        {showAdd ? "Show Equipments" : "Add Equipment"}
      </Button>
      <br />
      <br />
      <VerticallyCenteredModal
        title="Edit this Equipment ?"
        show={modalShow}
        size="sm"
        onHide={() => setModalShow(false)}
        onSubmit={submitEdit}
        setEdit={setEditEnabled}
      />
      <VerticallyCenteredModal
        title="Add this Equipment ?"
        show={addModalShow}
        size="sm"
        onHide={() => setAddModalShow(false)}
        onSubmit={handleAddEquipment}
      />
      {editEnabled ? (
        ""
      ) : showAdd ? (
        <Row
          className="mb-0"
          style={{
            margin: "auto",
            width: "95%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Form.Group as={Row} className="my-2" controlId="firtsName">
            <Form.Label column="true" sm="3">
              Select Equipment <label style={{ color: "red" }}>*</label> :
            </Form.Label>
            <Col xs={8} md={4} sm={8}>
              <Form.Select
                id="searchBy"
                value={equipmentName}
                onChange={(e) => handleSelect(e)}
                style={{ height: "100%" }}
              >
                {equipmentNames?.map((o) => (
                  <option key={o} value={o}>
                    {o}
                  </option>
                ))}
              </Form.Select>
              {equipmentNamePresent ? (
                <FieldWarning text="Please select equipment name" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="firtsName">
            <Form.Label column="true" sm="3">
              Equipment Count :
            </Form.Label>
            <Col xs={8} md={4} sm={8}>
              <Form.Group controlId="visitorMobile">
                <Form.Control
                  type="text"
                  pattern="[0-9]*" // Allow only digits
                  value={equipmentCount}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                    setEquipmentCount(value);
                  }}
                  placeholder="Equipment Count"
                />
              </Form.Group>
              {equipmentCountPresent ? (
                <FieldWarning text="Equipment count should be atleast 1 " />
              ) : (
                ""
              )}
            </Col>{" "}
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="firtsName">
            <Form.Label column="true" sm="3">
              Equipment Rate :
            </Form.Label>
            <Col xs={8} md={4} sm={8}>
              <Form.Group controlId="visitorMobile">
                <Form.Control
                  type="text"
                  pattern="[0-9]*" // Allow only digits
                  value={equipmentRate}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                    setEquipmentRate(value);
                  }}
                  placeholder="Equipment Rate"
                />
              </Form.Group>
              {equipmentRatePresent ? (
                <FieldWarning text="Rate should be atleast 1 Rs" />
              ) : (
                ""
              )}
            </Col>{" "}
          </Form.Group>

          <Form.Group as={Row} className="my-2" controlId="firtsName">
            <Form.Label column="true" sm="3">
              Equipment Size :
            </Form.Label>
            <Col xs={8} md={4} sm={8}>
              <Form.Group controlId="visitorMobile">
                <Form.Control
                  type="text"
                  pattern="[0-9]*" // Allow only digits
                  value={equipmentSize}
                  onChange={(e) => setEquipmentSize(e.target.value)}
                  placeholder="Equipment Size"
                />
              </Form.Group>
              {equipmentSizePresent ? (
                <FieldWarning text="equipment size should not be very long " />
              ) : (
                ""
              )}
            </Col>{" "}
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="firtsName">
            <Form.Label column="true" sm="3">
              Equipment Material :
            </Form.Label>
            <Col xs={8} md={4} sm={8}>
              <Form.Group controlId="notes">
                <Form.Control
                  type="text"
                  value={equipmentMaterial}
                  onChange={(e) => setEquipmentMaterial(e.target.value)}
                  placeholder="steel or wood or leather"
                />
              </Form.Group>
              {equipmentMaterialPresent ? (
                <FieldWarning text="equipment material should not be very long" />
              ) : (
                ""
              )}
            </Col>{" "}
          </Form.Group>

          <Form.Group as={Row} className="my-2" controlId="firtsName">
            <Form.Label column="true" sm="3">
              Purchased Date :
            </Form.Label>
            <Col xs={8} md={4} sm={8}>
              <Form.Group controlId="paidDate">
                <Form.Control
                  type="date"
                  value={purchasedDate}
                  onChange={(e) => setPurchasedDate(e.target.value)}
                  placeholder="purchased Date"
                />
              </Form.Group>
              {purchasedDatePresent ? (
                <FieldWarning text="Future date is not allowed" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="firtsName">
            <Form.Label column="true" sm="3">
              notes :
            </Form.Label>
            <Col xs={8} md={4} sm={8}>
              <Form.Group controlId="notes">
                <Form.Control
                  type="text"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  placeholder="Notes"
                />
              </Form.Group>
              {notesPresent ? (
                <FieldWarning text="notes should not have more then 1000 chars" />
              ) : (
                ""
              )}
            </Col>{" "}
          </Form.Group>

          <Form.Group as={Row} className="my-2" controlId="firtsName">
            <Col xs={6} md={8} sm={6} className="d-flex justify-content-center">
              <Button
                className={`${userInfo.themeColor}ThemeHeaderTop`}
                style={{ backgroundColor: "#124137" }}
                onClick={hanbdleAddExpense}
                variant="primary"
              >
                Add Equipment
              </Button>
            </Col>
          </Form.Group>
        </Row>
      ) : (
        <></>
      )}
      {!showAdd ? (
        <>
          {" "}
          <Row
            className="mb-0"
            style={{
              margin: "auto",
              width: "95%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Col xs={6} md="auto" sm={6}>
              <Form.Group controlId="visitorMobile">
                <Form.Control
                  type="date"
                  placeholder="2023-11-23"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col xs={6} md="auto" sm={6}>
              <Form.Group controlId="visitorMobile">
                <Form.Control
                  type="date"
                  placeholder="2023-11-27"
                  value={endDate?.split("T")[0]}
                  onChange={(e) => setEndDate(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>

            <Col
              xs={12}
              md="auto"
              sm={6}
              className="d-flex justify-content-center"
            >
              <Form.Group as={Row} className="my-1" controlId="gymType">
                <Button
                  className={`${userInfo.themeColor}ThemeHeaderTop`}
                  id="filterBy"
                  value={filterValue}
                  onClick={handleGetExpense}
                  style={{ backgroundColor: "#124137" }}
                >
                  Get Equipments
                </Button>
              </Form.Group>
            </Col>
            {!userInfo?.isMobileScreen && (
              <Col
                xs={6}
                md="auto"
                sm={6}
                className="d-flex justify-content-center"
              >
                <Button onClick={exportToExcel} variant="success">
                  Download Excel
                </Button>
              </Col>
            )}
          </Row>
          <br />
          {isLoading ? (
            <Loader />
          ) : error ? (
            <Message>{error?.data?.error}</Message>
          ) : (
            <div style={{ height: "100vh" }}>
              <div className="tableContainer">
                <Table
                  striped
                  bordered
                  hover
                  responsive="sm"
                  className="table-custom"
                >
                  <thead className={`${userInfo.themeColor}ThemeThead`}>
                    <tr>
                      <th
                        onClick={() => handleSort("equipmentName")}
                        style={{ cursor: "pointer" }}
                      >
                        Name <FaSort />
                      </th>
                      <th>Count</th>
                      <th>Rate</th>

                      <th>Size</th>
                      <th>Material</th>
                      <th
                        onClick={() => handleSort("purchasedDate")}
                        style={{ cursor: "pointer" }}
                      >
                        Purchased On <FaSort />
                      </th>
                      <th>Added By</th>
                      <th>Updated By</th>
                      <th>Updated On</th>
                      <th>Notes</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className={`${userInfo.themeColor}ThemeTbody`}>
                    {equipmentsToList ? (
                      equipmentsToList?.map((eq) => (
                        <>
                          {editEnabled === eq._id ? (
                            <tr>
                              <td>
                                <Form.Label style={{ color: "inherit" }}>
                                  {eq.equipmentName}
                                </Form.Label>
                              </td>
                              <td>
                                <Form.Control
                                  value={equipmentCount}
                                  type="text"
                                  onChange={(e) =>
                                    setEquipmentCount(e.target.value)
                                  }
                                ></Form.Control>
                                {equipmentCountPresent ? (
                                  <FieldWarning text="Please Provide valid Equipment count" />
                                ) : (
                                  ""
                                )}
                              </td>
                              <td>
                                <Form.Control
                                  value={equipmentRate}
                                  type="text"
                                  onChange={(e) =>
                                    setEquipmentRate(e.target.value)
                                  }
                                ></Form.Control>
                                {equipmentRatePresent ? (
                                  <FieldWarning text="Please Provide valid equipment rate" />
                                ) : (
                                  ""
                                )}
                              </td>
                              <td>
                                <Form.Control
                                  value={equipmentSize}
                                  type="text"
                                  onChange={(e) =>
                                    setEquipmentSize(e.target.value)
                                  }
                                ></Form.Control>
                                {equipmentSizePresent ? (
                                  <FieldWarning text="Please Provide valid equipment size" />
                                ) : (
                                  ""
                                )}
                              </td>
                              <td>
                                <Form.Control
                                  value={equipmentMaterial}
                                  type="text"
                                  onChange={(e) =>
                                    setEquipmentMaterial(e.target.value)
                                  }
                                ></Form.Control>
                                {equipmentMaterialPresent ? (
                                  <FieldWarning text="Please Provide valid equipmwnt material" />
                                ) : (
                                  ""
                                )}
                              </td>
                              <td>
                                <Form.Control
                                  value={purchasedDate?.split("T")[0]}
                                  type="date"
                                  onChange={(e) =>
                                    setPurchasedDate(e.target.value)
                                  }
                                ></Form.Control>
                                {purchasedDatePresent ? (
                                  <FieldWarning text="Please provide valid date in format DD/MM/YYYY" />
                                ) : (
                                  ""
                                )}
                              </td>
                              <td>
                                <Form.Label style={{ color: "inherit" }}>
                                  {eq.addedBy}
                                </Form.Label>
                              </td>
                              <td>
                                <Form.Label style={{ color: "inherit" }}>
                                  {eq.updatedBY?.toString().split("T")[0]}
                                </Form.Label>
                              </td>
                              <td>
                                <Form.Label style={{ color: "inherit" }}>
                                  {" "}
                                  {eq.updatedAt?.toString().split("T")[0]}
                                </Form.Label>
                              </td>
                              <td>
                                <Form.Control
                                  value={notes}
                                  type="text"
                                  onChange={(e) => setNotes(e.target.value)}
                                ></Form.Control>
                                {notesPresent ? (
                                  <FieldWarning text="notes should not have more then 1000 chars" />
                                ) : (
                                  ""
                                )}
                              </td>
                              <td>
                                {editEnabled ? (
                                  <>
                                    <button
                                      onClick={hanbdleSubmit}
                                      style={{
                                        borderRadius: "10%",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      <CheckLg />
                                    </button>
                                    <button
                                      onClick={hanbdleCancelEdit}
                                      style={{
                                        borderRadius: "10%",
                                        margin: "8%",
                                      }}
                                    >
                                      <XLg />
                                    </button>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td>{eq.equipmentName}</td>
                              <td>{eq.equipmentCount}</td>
                              <td>{eq.equipmentRate}</td>
                              <td>{eq.equipmentSize}</td>
                              <td>{eq.equipmentMaterial}</td>
                              <td style={{ whiteSpace: "nowrap" }}>
                                {eq.purchasedDate
                                  ? eq.purchasedDate?.split("T")[0]
                                  : ""}
                              </td>
                              <td>{eq.addedBy}</td>
                              <td>{eq.updatedBy}</td>
                              <td style={{ whiteSpace: "nowrap" }}>
                                {eq.updatedAt
                                  ? eq.updatedAt?.split("T")[0]
                                  : ""}
                              </td>
                              <td>{eq.notes}</td>
                              <td>
                                <button
                                  style={{ borderRadius: "20%" }}
                                  onClick={() => {
                                    setEquipmentName(eq.equipmentName);
                                    setEquipmentCount(eq.equipmentCount);
                                    setEquipmentMaterial(eq.equipmentMaterial);
                                    setEditEnabled(eq._id);
                                    setNotes(eq.notes);
                                    setEquipmentRate(eq.equipmentRate);
                                    setPurchasedDate(eq.purchasedDate);
                                    setEquipmentSize(eq.equipmentSize);
                                  }}
                                >
                                  <Pencil />
                                </button>
                              </td>
                            </tr>
                          )}
                        </>
                      ))
                    ) : (
                      <Message>No Equipments Available</Message>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default EquipmentsScreen;
