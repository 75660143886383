import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import { useNavigate } from "react-router-dom";
import { useLogoutMutation } from "../slices/gymSlice";
import { logout } from "../slices/authSlice";
import { removeUsers } from "../slices/userSlice";
import {
  FaUser,
  FaRegListAlt,
  FaUsers,
  FaUsersSlash,
  FaMoneyBillAlt,
  FaUserPlus,
  FaClipboardList,
  FaChartLine,
  FaWhatsappSquare,
  FaPhoneAlt,
  FaUserCircle,
  FaWhatsapp,
  FaRegEdit,
  FaMobileAlt,
} from "react-icons/fa"; // Add the icons you need
import { FaClock } from "react-icons/fa6";
import {
  RiPassPendingFill,
  RiFolderUploadFill,
  RiQrCodeFill,
  RiBuilding4Fill,
  RiLogoutCircleRLine,
} from "react-icons/ri";
import { CgGym, CgProfile, CgMenuBoxed } from "react-icons/cg";
import {
  MdAccountBalance,
  MdQrCode2,
  MdManageAccounts,
  MdCalendarToday,
} from "react-icons/md";

import { removeAttendances } from "../slices/attendanceListSlice";
import { removeSelfUsers } from "../slices/userSelfSlice";
import preferredLanguage from "../assets/preferredLanguage.json";

const HeaderTop = function () {
  const { userInfo } = useSelector((state) => state.auth);
  const selectedLanguage = userInfo?.preferredLanguage
    ? userInfo?.preferredLanguage
    : "English";
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [logoutApi] = useLogoutMutation();
  let gymId;
  if (userInfo) {
    gymId = userInfo.gymId._id;
  }
  const handleLogout = async () => {
    try {
      await logoutApi().unwrap();
      dispatch(logout());
      dispatch(removeUsers());
      dispatch(removeAttendances());
      dispatch(removeSelfUsers());
      navigate("/login");
      toast.success("Successfully logged out");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <header
      className={`${userInfo ? userInfo.themeColor : "cyan"}ThemeHeaderTop`}
      style={{
        position: "sticky",
        top: "0px",
        zIndex: 1,
        padding: "0.5rem 0rem",
        marginTop: "0.03rem",
      }}
    >
      <Navbar
        expand="md"
        collapseOnSelect
        style={{
          height: "50px",
          backgroundColor: "inherit",
        }}
      >
        <Container
          style={{
            backgroundColor: "inherit",
          }}
        >
          {!userInfo && <h6>zpotter Technologies</h6>}

          {userInfo ? (
            <div
              className={`${userInfo?.themeColor}ThemeHeaderTop`}
              style={{
                width: "80%",
                backgroundColor: "#000",
                display: "flex",
                justifyContent: "space-around",
                zIndex: 1000,
              }}
            >
              <Link
                to="/profile"
                style={{ ...menuButtonStyle, textDecoration: "none" }}
              >
                <FaUser
                  style={{
                    width: "1.5rem",
                    height: "1.5rem",
                    marginBottom: "0.3rem",
                  }}
                />

                {userInfo?.firstName.split(" ")[0]}
              </Link>

              <Link
                to="/markAttendance"
                style={{ ...menuButtonStyle, textDecoration: "none" }}
              >
                <FaClock
                  style={{
                    width: "1.5rem",
                    height: "1.5rem",
                    marginBottom: "0.3rem",
                  }}
                />
                {preferredLanguage[selectedLanguage]?.directMenu.inOut}
              </Link>
              <Link
                to="/attendanceList"
                style={{ ...menuButtonStyle, textDecoration: "none" }}
              >
                <FaClipboardList
                  style={{
                    width: "1.5rem",
                    height: "1.5rem",
                    marginBottom: "0.3rem",
                  }}
                />
                {preferredLanguage[selectedLanguage]?.directMenu.att}
              </Link>
              {(userInfo.role === "owner" ||
                userInfo.role === "Super Admin") && (
                <Link
                  to="/trends"
                  style={{ ...menuButtonStyle, textDecoration: "none" }}
                >
                  <MdCalendarToday
                    style={{
                      width: "1.5rem",
                      height: "1.5rem",
                      marginBottom: "0.3rem",
                    }}
                  />
                  {preferredLanguage[selectedLanguage]?.directMenu.today}
                </Link>
              )}

              {(userInfo.role === "owner" ||
                userInfo.role === "Super Admin") && (
                <Link
                  to="/accounts"
                  style={{ ...menuButtonStyle, textDecoration: "none" }}
                >
                  <MdAccountBalance
                    style={{
                      width: "1.5rem",
                      height: "1.5rem",
                      marginBottom: "0.3rem",
                    }}
                  />
                  {preferredLanguage[selectedLanguage]?.directMenu.accounts}
                </Link>
              )}
            </div>
          ) : (
            <></>
          )}

          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            style={{ marginRight: "1%", backgroundColor: "inherit" }}
          />
          <Navbar.Collapse
            id="basic-navbar-nav"
            style={{ marginRight: "1%", backgroundColor: "inherit" }}
          >
            <Nav className="ms-auto" style={{ backgroundColor: "inherit" }}>
              {userInfo ? (
                <>
                  {/* <LinkContainer to="/markAttendance">
                    <Nav.Link>
                      {" "}
                      {preferredLanguage[selectedLanguage]?.attendance}
                    </Nav.Link>
                  </LinkContainer> */}

                  {/* {userInfo.role === "owner" ||
                  userInfo.role === "Super Admin" ? (
                    <LinkContainer to="/trends">
                      <Nav.Link>
                        {" "}
                        <FaUserPlus style={{ marginRight: "2px" }} />
                        {
                          preferredLanguage[selectedLanguage]?.attendancePage
                            .todaysTrend
                        }
                      </Nav.Link>
                    </LinkContainer>
                  ) : (
                    <></>
                  )} */}
                  {/* <LinkContainer to="/userRegistration">
                    <Nav.Link>
                      {" "}
                      {preferredLanguage[selectedLanguage]?.userReg}
                    </Nav.Link>
                  </LinkContainer> */}
                  {/* <LinkContainer to="/visitors">
                    <Nav.Link>
                      {" "}
                      {preferredLanguage[selectedLanguage]?.visitors}
                    </Nav.Link>
                  </LinkContainer> */}
                  {/* <LinkContainer to="/attendanceList">
                    <Nav.Link>
                      {" "}
                      {preferredLanguage[selectedLanguage]?.attendanceList}
                    </Nav.Link>
                  </LinkContainer> */}

                  <NavDropdown
                    title={
                      preferredLanguage[selectedLanguage]?.memberManagement
                    }
                  >
                    {/* <LinkContainer to="/allUsers">
                      <NavDropdown.Item>
                        <FaUsers style={{ marginRight: "2px" }} />
                        {preferredLanguage[selectedLanguage]?.allMembers}
                      </NavDropdown.Item>
                    </LinkContainer> */}
                    <LinkContainer to="/users">
                      <NavDropdown.Item>
                        <FaUsers style={{ marginRight: "2px" }} />
                        {preferredLanguage[selectedLanguage]?.activeMember}
                      </NavDropdown.Item>
                    </LinkContainer>
                    {/* <LinkContainer to="/subsExpiredUsers">
                      <NavDropdown.Item>
                        <FaUsersSlash style={{ marginRight: "2px" }} />
                        {preferredLanguage[selectedLanguage]?.inActiveMembers}
                      </NavDropdown.Item>
                    </LinkContainer> */}
                    {/* {userInfo.role === "owner" ||
                    userInfo.role === "Super Admin" ? (
                      <LinkContainer to="/payments">
                        <NavDropdown.Item>
                          <FaMoneyBillAlt style={{ marginRight: "2px" }} />
                          {preferredLanguage[selectedLanguage]?.paymentsList}
                        </NavDropdown.Item>
                      </LinkContainer>
                    ) : (
                      <></>
                    )} */}

                    {/* <LinkContainer to="/regPendingUsers">
                      <NavDropdown.Item>
                        <RiPassPendingFill style={{ marginRight: "2px" }} />
                        {preferredLanguage[selectedLanguage]?.pendingReg}
                      </NavDropdown.Item>
                    </LinkContainer> */}
                    {userInfo.role === "owner" ||
                    userInfo.role === "Super Admin" ||
                    userInfo.role === "Admin" ? (
                      <>
                        {" "}
                        <LinkContainer to="/uploadUsers">
                          <NavDropdown.Item>
                            <RiFolderUploadFill
                              style={{ marginRight: "2px" }}
                            />
                            {preferredLanguage[selectedLanguage]?.bulkUpload}
                          </NavDropdown.Item>
                        </LinkContainer>{" "}
                        <LinkContainer to="/whatsAppMessages">
                          <NavDropdown.Item>
                            {" "}
                            <FaWhatsappSquare style={{ marginRight: "2px" }} />
                            {
                              preferredLanguage[selectedLanguage]
                                ?.wsNotification
                            }
                          </NavDropdown.Item>
                        </LinkContainer>
                      </>
                    ) : (
                      ""
                    )}

                    {userInfo.role === "owner" ||
                    userInfo.role === "Super Admin" ? (
                      <LinkContainer to="/promotionalMessage">
                        <NavDropdown.Item>
                          {" "}
                          <FaWhatsapp style={{ marginRight: "2px" }} />
                          {preferredLanguage[selectedLanguage]?.sendAds}
                        </NavDropdown.Item>
                      </LinkContainer>
                    ) : (
                      ""
                    )}
                  </NavDropdown>
                  <NavDropdown
                    title={preferredLanguage[selectedLanguage]?.gymManagement}
                  >
                    {userInfo.role === "owner" ||
                    userInfo.role === "Super Admin" ||
                    userInfo.role === "Admin" ? (
                      <LinkContainer to="/equipments">
                        <NavDropdown.Item>
                          {" "}
                          <CgGym style={{ marginRight: "2px" }} />
                          {
                            preferredLanguage[selectedLanguage]
                              ?.equipmentManagement
                          }
                        </NavDropdown.Item>
                      </LinkContainer>
                    ) : (
                      <></>
                    )}
                    {/* {userInfo.role === "owner" ||
                    userInfo.role === "Super Admin" ? (
                      <LinkContainer to="/accounts">
                        <NavDropdown.Item>
                          {" "}
                          <MdAccountBalance style={{ marginRight: "2px" }} />
                          {preferredLanguage[selectedLanguage]?.accounts}
                        </NavDropdown.Item>
                      </LinkContainer>
                    ) : (
                      <></>
                    )} */}

                    <LinkContainer to="/associatesAttendance">
                      <NavDropdown.Item>
                        {" "}
                        <MdManageAccounts style={{ marginRight: "2px" }} />
                        {
                          preferredLanguage[selectedLanguage]
                            ?.associateAttendanceList
                        }
                      </NavDropdown.Item>
                    </LinkContainer>

                    <LinkContainer to="/associates">
                      <NavDropdown.Item>
                        {" "}
                        <MdManageAccounts style={{ marginRight: "2px" }} />
                        {preferredLanguage[selectedLanguage]?.adminManagement}
                      </NavDropdown.Item>
                    </LinkContainer>

                    {userInfo.role === "owner" ||
                    userInfo.role === "Super Admin" ||
                    userInfo.role === "Admin" ? (
                      <LinkContainer to="/dashboard">
                        <NavDropdown.Item>
                          <FaChartLine style={{ marginRight: "2px" }} />
                          {preferredLanguage[selectedLanguage]?.analytics}
                        </NavDropdown.Item>
                      </LinkContainer>
                    ) : (
                      <></>
                    )}
                    {/* <LinkContainer to="/usersAtGym/896041834487">
                      <NavDropdown.Item>Numbers to Users</NavDropdown.Item>
                    </LinkContainer> */}
                  </NavDropdown>

                  <NavDropdown title={userInfo.firstName}>
                    {/* <LinkContainer to="/profile">
                      <NavDropdown.Item>
                        {" "}
                        <CgProfile style={{ marginRight: "3px" }} />
                        {preferredLanguage[selectedLanguage]?.myProfile}
                      </NavDropdown.Item>
                    </LinkContainer> */}
                    {userInfo.role === "owner" ||
                    userInfo.role === "Super Admin" ? (
                      <>
                        <LinkContainer to="/gymProfile">
                          <NavDropdown.Item>
                            {" "}
                            <RiBuilding4Fill style={{ marginRight: "3px" }} />
                            {preferredLanguage[selectedLanguage]?.gymProfile}
                          </NavDropdown.Item>
                        </LinkContainer>
                        <LinkContainer to="/updateAttendancePageScreen">
                          <NavDropdown.Item>
                            <FaRegEdit style={{ marginRight: "2px" }} />
                            {preferredLanguage[selectedLanguage]?.editAttPage}
                          </NavDropdown.Item>
                        </LinkContainer>
                        <LinkContainer to="/packages">
                          <NavDropdown.Item>
                            <CgMenuBoxed style={{ marginRight: "2px" }} />
                            Packages
                          </NavDropdown.Item>
                        </LinkContainer>
                      </>
                    ) : (
                      <></>
                    )}

                    <LinkContainer to="/qrCode">
                      <NavDropdown.Item>
                        <MdQrCode2 style={{ marginRight: "2px" }} />
                        {preferredLanguage[selectedLanguage]?.attendanceQrCode}
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/qrcodeRegister">
                      <NavDropdown.Item>
                        <RiQrCodeFill style={{ marginRight: "2px" }} />
                        {preferredLanguage[selectedLanguage]?.regQrCode}
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/downloadApps">
                      <NavDropdown.Item>
                        <FaMobileAlt style={{ marginRight: "2px" }} />
                        Mobile Apps
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/contactUs">
                      <NavDropdown.Item>
                        {" "}
                        <FaPhoneAlt style={{ marginRight: "2px" }} />
                        {preferredLanguage[selectedLanguage]?.contactUs}
                      </NavDropdown.Item>
                    </LinkContainer>
                    <NavDropdown.Item onClick={handleLogout}>
                      <RiLogoutCircleRLine style={{ marginRight: "3px" }} />
                      {preferredLanguage[selectedLanguage]?.logout}
                    </NavDropdown.Item>
                  </NavDropdown>
                </>
              ) : (
                // <LinkContainer to="/">
                //   <Nav.Link>
                //     {preferredLanguage[selectedLanguage]?.login}
                //   </Nav.Link>
                // </LinkContainer>
                <></>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

const menuButtonStyle = {
  background: "none",
  border: "none",
  fontSize: "0.55rem",
  color: "white",
  cursor: "pointer",
  outline: "none",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  width: "100%",
};

export default HeaderTop;
